import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Table } from '@hexure/ui';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Modal from 'shared/Modal';
import Select from 'shared/Select';

const tobaccoTypes = [
  { value: 'Cigarettes' },
  { value: 'Cigars' },
  { value: 'Pipe' },
  { value: 'Chewing Tobacco' },
  { value: 'Nicotine Gum' },
  { value: 'Nicotine Patch' }
];

const marijuanaTypes = [
  { value: 'Marijuana (Smoking/Vaping)' },
  { value: 'Marijuana (Ingested)' }
];

const HealthAnalyzerModal = ({ data, isVive, disableQuoter, onApply, onClose }) => {
  const [state, setState] = useState(data);
  const [hideCarrierList, setHideCarrierList] = useState(true);
  const [errors, setErrors] = useState([]);

  const updateState = (updates) => setState((prev) => ({ ...prev, ...updates }));

  const handleDataChange = (name, e) => {
    const value = e.target.value;
    const updatedState = { ...state, [name]: value };

    const newErrors = [];
    if (!updatedState.weight) newErrors.push('weight');
    if (updatedState.tobacco_type === 'Cigars' && updatedState.no_of_cigars < 1) newErrors.push('no_of_cigars');
    if (marijuanaTypes.some(type => type.value === state.tobacco_type) && updatedState.no_of_marijuana < 1) newErrors.push('no_of_marijuana');

    setErrors(newErrors);
    updateState(updatedState);
  };

  const handleTobaccoChange = (e) => {
    const isTobaccoUsed = e.target.value === 'true';
    updateState({
      tobacco: isTobaccoUsed,
      tobacco_last_used: isTobaccoUsed ? 'within_last_year' : 'Never',
      tobacco_type: isTobaccoUsed ? 'Cigarettes' : 'None',
      no_of_cigars: 0
    });
  };

  const handleTobaccoTypeChange = (e) => {
    const { value } = e.target;
    updateState({
      tobacco_type: value,
      no_of_cigars: value === 'Cigars' ? 1 : 0,
      no_of_marijuana: marijuanaTypes.some(type => type.value === value) ? 1 : 0
    });
  };

  const handleTobaccoLastUsedChange = (e) => {
    const { value } = e.target;
    updateState({
      tobacco_last_used: value,
      tobacco_type: value === 'Never' ? 'None' : state.tobacco_type,
      no_of_cigars: value === 'Never' ? 0 : state.no_of_cigars
    });
  };

  return hideCarrierList ? (
    <Modal
      buttons={[
        {
          children: 'Apply Health Information',
          disabled: !!errors.length,
          onClick: () => onApply(state),
          style: { marginLeft: 'auto' }
        }
      ]}
      maxWidth={650}
      onClose={() => onClose(null)}
      title="Health Analyzer"
    >
      <div style={{ width: 650, padding: '30px 30px 15px' }}>
        <Alert
          action={{ label: 'View Supported Carriers', onClick: () => setHideCarrierList(false) }}
          description="The Health Analyzer is not currently supported by all carriers on the quoting platform. Using the Health Analyzer will only return quotes from supported carriers."
          title="Carrier Support"
          type="warning"
        />
        <div style={{ display: 'flex', columnGap: 20, marginTop: 20 }}>
          <Field label="Height" style={{ flex: 1 }}>
            <div style={{ display: 'flex', columnGap: 10 }}>
              <Select
                onChange={(e) => handleDataChange('height_feet', e)}
                options={Array.from({ length: 9 }, (_, i) => ({ value: i.toString(), label: `${i} ft` }))}
                readOnly={disableQuoter}
                required
                value={state.height_feet}
              />
              <Select
                onChange={(e) => handleDataChange('height_inch', e)}
                options={Array.from({ length: 12 }, (_, i) => ({ value: i.toString(), label: `${i} in` }))}
                readOnly={disableQuoter}
                required
                value={state.height_inch}
              />
            </div>
          </Field>
          <Field invalid={errors.includes('weight')} label="Weight" style={{ flex: 1 }}>
            <Input
              invalid={errors.includes('weight')}
              max={1400}
              onChange={(e) => handleDataChange('weight', e)}
              readOnly={disableQuoter}
              required
              suffix="lbs"
              type="number"
              value={state.weight}
            />
          </Field>
        </div>

        <Field label={isVive ? 'Is the proposed insured a tobacco or marijuana user?' : 'Is the proposed insured a tobacco user?'}>
          <Select
            onChange={handleTobaccoChange}
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' }
            ]}
            readOnly={disableQuoter}
            value={state.tobacco.toString()}
          />
        </Field>

        {state.tobacco && (
          <Field label={isVive ? 'What kind do they use?' : 'What kind of tobacco do they use?'}>
            <Select
              onChange={handleTobaccoTypeChange}
              options={isVive ? [...tobaccoTypes, ...marijuanaTypes] : [...tobaccoTypes]}
              readOnly={disableQuoter}
              required
              value={state.tobacco_type}
            />
          </Field>
        )}

        {tobaccoTypes.some(type => type.value === state.tobacco_type) && (
          <Field label="When was the last time they used tobacco?">
            <Select
              onChange={handleTobaccoLastUsedChange}
              options={[
                { label: 'Within the last year', value: 'within_last_year' },
                { label: '1-2 Years', value: '1_2_Years' },
                { label: '2-3 Years', value: '2_3_Years' },
                { label: '4-5 Years', value: '4_5_Years' },
                { label: 'More than 5 years', value: 'More_than_5_years' }
              ]}
              readOnly={disableQuoter}
              required
              value={state.tobacco_last_used}
            />
          </Field>
        )}

        {state.tobacco_type === 'Cigars' && (
          <Field invalid={errors.includes('no_of_cigars')} label="How many cigars do they smoke each year?">
            <Input
              invalid={errors.includes('no_of_cigars')}
              onChange={(e) => handleDataChange('no_of_cigars', e)}
              readOnly={disableQuoter}
              step="1"
              type="number"
              value={state.no_of_cigars}
            />
          </Field>
        )}

        {marijuanaTypes.some(type => type.value === state.tobacco_type) && (
          <Field invalid={errors.includes('no_of_marijuana')} label="How often do they use marijuana each month?">
            <Input
              invalid={errors.includes('no_of_marijuana')}
              onChange={(e) => handleDataChange('no_of_marijuana', e)}
              readOnly={disableQuoter}
              step="1"
              type="number"
              value={state.no_of_marijuana}
            />
          </Field>
        )}
      </div>
    </Modal>
  ) : (
    <Modal buttons={[{ color: '#e5e5e5', fontColor: '#444', children: 'Back To Health Analyzer', onClick: () => setHideCarrierList(true) }]} maxWidth={650} title="Supported Carriers">
      <div style={{ width: 650, padding: '30px 30px 15px' }}>
        <Table columns={[{ id: 'name', label: 'Carrier Name' }]} data={[{ name: 'American General Life Insurance' }, { name: 'United of Omaha Life Insurance Company' }]} />
      </div>
    </Modal>
  );
};

HealthAnalyzerModal.propTypes = {
  data: PropTypes.object,
  disableQuoter: PropTypes.bool,
  onApply: PropTypes.func,
  onClose: PropTypes.func
};

export default HealthAnalyzerModal;
