import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Colors, FontSizes } from 'constants/Clementine';
import { FontStyles } from '@hexure/ui';

const MultiSelect = ({ disabled, id = 'multi-select', displayCount = 3, invalid, onChange, optionHeight, options, required, readOnly, selected, selectAll, style }) => {
  const [show_options, setShowOptions] = useState(false);
  const styles = {
    component: Object.assign(
      {},
      {
        position: 'relative'
      },
      style
    ),
    trigger: {
      borderRadius: 4,
      color: Colors.GRAY.hex,
      fontSize: FontSizes.REGULAR,
      fontWeight: 400,
      lineHeight: '40px',
      height: 40,
      padding: '0 10px',
      background: '#fff',
      position: 'relative',
      cursor: 'pointer',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: invalid ? Colors.RED.hex : '#e5e5e5',
      backgroundColor: readOnly ? '#f5f5f5' : '#fff',
      backgroundImage: 'none',
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      fontSize: 18,
      fill: Colors.GRAY.hex,
      marginLeft: 'auto'
    },
    required: {
      position: 'absolute',
      lineHeight: '1',
      right: 5,
      top: 5,
      color: Colors.RED.hex,
      fontSize: FontSizes.LARGE
    },
    value: {
      width: '100%',
      color: Colors.Black,
      fontFamily: FontStyles.DEFAULT,
      fontSize: FontSizes.DEFAULT,
      fontWeight: 400,
      lineHeight: '2.9em',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    options: {
      background: '#fff',
      display: show_options ? 'block' : 'none',
      position: 'absolute',
      border: '1px solid #52ADFF',
      borderRadius: '0px 0px 4px 4px',
      boxShadow: '0px 15px 30px -15px rgba(0,0,0,0.2)',
      left: 0,
      right: 0,
      maxHeight: optionHeight || '220px',
      overflow: 'auto',
      zIndex: 9999,
      fontSize: FontSizes.REGULAR
    },
    option: {
      padding: '6px 12px',
      display: 'flex',
      alignItems: 'flex-start',
      cursor: 'pointer'
    },
    checkbox: {
      marginRight: 8
    },
    label: {
      marginTop: -1,
      lineHeight: '16px'
    },
    select_all: {
      paddingBottom: 12,
      marginBottom: 8,
      marginLeft: 12,
      marginRight: 12,
      paddingLeft: 0,
      paddingRight: 0,
      borderBottom: '1px solid #e5e5e5'
    },
    scrim: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1
    }
  };
  const _toggleOptions = () => {
    setShowOptions(!show_options);
  };
  const _selectAll = () => {
    if (selected.length === options.length) {
      onChange([]);
    } else {
      onChange(options.map(o => o.value));
    }
  };
  const _onChange = o => {
    const updated_selected = selected.filter(s => s !== o.value);

    if (!selected.includes(o.value)) {
      updated_selected.push(o.value);
    }

    onChange(updated_selected);
  };
  const selected_options = options.filter(o => selected.includes(o.value));

  return (
    <div id={id} style={styles.component}>
      <div onClick={_toggleOptions} style={styles.trigger}>
        <div style={styles.value}>{selected.length > 0 && selected.length <= displayCount ? selected_options.map(o => o.label || o.value).join(', ') : `${selected.length} Selected`}</div>

        <i className='mdi mdi-chevron-down' style={styles.icon} />

        {required ? <span style={styles.required}>*</span> : null}
      </div>

      {show_options ? (
        <div style={styles.options}>
          {selectAll ? (
            <div onClick={disabled ? null : _selectAll} style={{ ...styles.option, ...styles.select_all }}>
              <input checked={selected.length === options.length} disabled={disabled} readOnly={true} style={styles.checkbox} type='checkbox' />
              <div style={styles.label}>{selected.length === options.length ? 'Unselect' : 'Select'} All</div>
            </div>
          ) : null}

          {options.map((o, i) => {
            const is_selected = selected.includes(o.value);
            const label = o.label || o.value;

            return (
              <div key={i} onClick={disabled ? null : _onChange.bind(null, o)} style={styles.option}>
                <input checked={is_selected} disabled={disabled} readOnly={true} style={styles.checkbox} type='checkbox' />
                <div style={styles.label}>{label}</div>
              </div>
            );
          })}
        </div>
      ) : null}

      {show_options ? <div onClick={_toggleOptions} style={styles.scrim} /> : null}
    </div>
  );
};

MultiSelect.propTypes = {
  disabled: PropTypes.bool,
  displayCount: PropTypes.number,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  optionHeight: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  selectAll: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired
};

export default MultiSelect;
