import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';

import Modal from 'shared/Modal';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';
import FormUtils from 'utils/Form';

import { ProductContext } from 'alq/Context';

const QUOTER_CONFIG = window.IXN_QUOTER_CONFIG;
const action_defaults = {
  request_forms: {
    icon: 'mdi-information-outline',
    label: 'Request Forms'
  },
  webhook_post: {
    icon: 'mdi-open-in-new',
    label: ''
  },
  simple_url: {
    icon: 'mdi-open-in-new',
    label: ''
  },
  default: {
    icon: 'mdi-open-in-new',
    label: 'Send To Form'
  },
  typeform: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  formassembly: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  '123_contact_form': {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  cognito_form: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  formsite: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  jotform: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  formstack: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  wufoo: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  google_form: {
    icon: 'mdi-file-document-box-outline',
    label: 'Send To Form'
  },
  applicant: {
    icon: 'mdi-file-document-outline',
    label: 'ExpressComplete'
  },
  nmb_term_eticket: {
    icon: 'mdi-flash',
    label: 'E-Ticket'
  },
  nmb_whole_life_eticket: {
    icon: 'mdi-flash',
    label: 'E-Ticket'
  },
  paclife: {
    icon: 'mdi-flash',
    label: 'Apply',
    show: quote => {
      let show = true;

      if (quote.rate_age < 18) {
        show = false;
      }

      if (quote.face_amount < 50000 && quote.product_type.includes('Term')) {
        show = false;
      }

      if (quote.face_amount < 25000 && quote.product_type.includes('GUL')) {
        show = false;
      }

      if (quote.face_amount > 1000000000) {
        show = false;
      }

      return show;
    }
  },
  sbli: {
    icon: 'mdi-flash',
    label: 'Apply',
    show: quote => {
      let show = true;

      if (quote.rate_age < 18 || quote.face_amount < 100000) {
        show = false;
      } else {
        if (quote.product_type === '10 Year Term') {
          const max_age = quote.tobacco ? 70 : 74;

          if (quote.rate_age > max_age) {
            show = false;
          }

          if (quote.face_amount > 10000000) {
            show = false;
          }
        }

        if (quote.product_type === '15 Year Term') {
          const max_age = quote.tobacco ? 60 : 65;

          if (quote.rate_age > max_age) {
            show = false;
          }

          if (quote.face_amount > 50000000) {
            show = false;
          }
        }

        if (quote.product_type === '20 Year Term') {
          const max_age = quote.tobacco ? 55 : 60;

          if (quote.rate_age > max_age) {
            show = false;
          }

          if (quote.face_amount > 50000000) {
            show = false;
          }
        }

        if (quote.product_type === '25 Year Term') {
          const max_age = quote.tobacco ? 50 : 55;

          if (quote.rate_age > max_age) {
            show = false;
          }

          if (quote.face_amount > 50000000) {
            show = false;
          }
        }

        if (quote.product_type === '30 Year Term') {
          const max_age = quote.tobacco ? 45 : 50;

          if (quote.rate_age > max_age) {
            show = false;
          }

          if (quote.face_amount > 50000000) {
            show = false;
          }
        }
      }

      return show;
    }
  },
  anico: {
    icon: 'mdi-flash',
    label: 'E-Ticket',
    show: quote => {
      return quote.state !== 'NY' && quote.state !== 'PA';
    }
  },
  protective: {
    icon: 'mdi-flash',
    label: 'E-Ticket',
    show: quote => {
      return quote.state !== 'NY' && quote.rate_age >= 18;
    }
  },
  firelight: {
    icon: 'mdi-file-document-edit-outline',
    label: 'FireLight'
  },
  psg_eapp: {
    icon: 'mdi-file-document-edit-outline',
    label: 'E-Application'
  },
  download_quotes_pdf: {
    icon: 'mdi-file-download-outline',
    label: 'Download Quote PDF'
  },
  send_quote_as_email: {
    icon: 'mdi-email-outline',
    label: 'Email Quote PDF'
  },
  send_quote_as_text: {
    icon: 'mdi-forum-outline',
    label: 'Text Quote PDF'
  },
  standard_packet: {
    icon: 'mdi-file-multiple-outline',
    label: 'Standard Packet'
  }
};
// Never shown in FireLight
const fl_disabled_action_types = ['applicant', 'firelight', 'psg_eapp'];
// Hidden in FireLight if FL apply action is available
const fl_restricted_action_types = ['anico', 'nmb_term_eticket', 'nmb_whole_life_eticket', 'paclife', 'protective', 'request_forms', 'sbli', 'standard_packet'];

class QuoteActionModal extends React.Component {
  static propTypes = {
    onAction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    quote: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props);

    this.state = {
      loading: false,
      hovering_id: null,
      agent_email: context.agent.email || '',
      agent_name: `${context.agent.first_name} ${context.agent.last_name}`,
      agent_phone_number: context.agent.phone || '',
      agent_license_number: context.agent.insurance_license_number || '',
      client_name: `${context.client.first_name} ${context.client.last_name}`
    };
  }

  _handleHover = hovering_id => {
    this.setState({ hovering_id });
  };

  _handleFireLightQuoteAction = (onClick, quote) => {
    const { current_age, date_of_birth, nearest_age } = this.context.ran_quote_data;
    const ages_from_dob = FormUtils._getAgesFromDob(date_of_birth);

    this.setState({ loading: true });
    this._generateQuotePdf(quote).then(response => {
      onClick({
        agent: this.context.agent,
        client: this.context.client,
        quote: { ...quote, pdf_url: response.data.url },
        quoted_config: {
          ...this.context.ran_quote_data,
          date_of_birth: this.context.ran_quote_data.date_of_birth ? Moment(this.context.ran_quote_data.date_of_birth).format('YYYY-MM-DD') : null,
          current_age: current_age ? current_age : ages_from_dob.calculated_current_age,
          nearest_age: nearest_age ? nearest_age : ages_from_dob.calculated_nearest_age
        }
      });
      this.props.onClose();
      this.setState({ loading: false });
    });
  };

  _generateQuotePdf = quote => {
    const { agent_email, agent_license_number, agent_name, agent_phone_number, client_name } = this.state;
    return AlqApi.generateQuotesPdf({
      quote_guids: [quote.guid],
      template: 'default',
      pdf_action: 'download',
      show_agent_info: true,
      agent_email,
      agent_name,
      agent_license_number,
      agent_phone_number,
      client_name
    });
  };

  _fireLightActionIsValid = quote_action => {
    const embed_config_quote_actions = this.context.alq.embed_config_quote_actions;
    const fl_apply_quote_action = embed_config_quote_actions?.find(action => action.type === 'fl_apply');

    if (fl_disabled_action_types?.includes(quote_action.action_type)) {
      return false;
    } else if (fl_apply_quote_action && fl_apply_quote_action.enabled_quote_guids?.includes(this.props.quote.guid) && fl_restricted_action_types?.includes(quote_action.action_type)) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { onAction, onClose, quote } = this.props;
    const { loading, hovering_id } = this.state;
    const styles = this.styles();

    return (
      <Modal loading={loading} maxWidth={800} onClose={onClose} title='Select An Action'>
        <div style={styles.content}>
          <div style={styles.wrapper}>
            {this.context.alq.embed_config_quote_actions
              ? this.context.alq.embed_config_quote_actions.map((action, index) => {
                  if (action.enabled_quote_guids.includes(quote.guid)) {
                    return (
                      <div
                        key={action.id || index}
                        onClick={QUOTER_CONFIG.embed_context === 'fire_light' ? this._handleFireLightQuoteAction.bind(null, action.on_click, quote) : action.on_click.bind(null, quote)}
                        onMouseEnter={this._handleHover.bind(null, action.id || index)}
                        onMouseLeave={this._handleHover.bind(null, null)}
                        style={Object.assign({}, styles.box_button, (hovering_id === action.id || hovering_id === index) && styles.box_button_selected)}
                      >
                        <i className={`mdi mdi-${action.icon}`} style={styles.box_button_icon} />
                        <div style={styles.box_button_label}>{action.label}</div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              : null}

            {this.context.alq.custom_actions.map(action => {
              const carrier_ids = action.carrier_ids || [];
              const product_ids = action.product_ids || [];
              const action_config = action_defaults[action.action_type] || {};
              const show = action_config.show ? action_config.show(quote) : true;
              const min_face_amount = action.min_face_amount || 0;
              const max_face_amount = action.max_face_amount || 1000000000;
              const valid_face_amount = quote.face_amount >= min_face_amount && quote.face_amount <= max_face_amount;

              if (
                carrier_ids.includes(quote.carrier_id) &&
                (!product_ids.length || product_ids.includes(quote.product_id)) &&
                show &&
                valid_face_amount &&
                (QUOTER_CONFIG.embed_context !== 'fire_light' || this._fireLightActionIsValid(action))
              ) {
                return (
                  <div
                    key={action.id}
                    onClick={onAction.bind(null, action)}
                    onMouseEnter={this._handleHover.bind(null, action.id)}
                    onMouseLeave={this._handleHover.bind(null, null)}
                    style={Object.assign({}, styles.box_button, hovering_id === action.id && styles.box_button_selected)}
                  >
                    {action.icon && action.icon.url ? (
                      <div style={Object.assign({ backgroundImage: 'url(' + action.icon.url + ')' }, styles.box_button_image)} />
                    ) : (
                      <i className={`mdi ${action_defaults[action.action_type].icon}`} style={styles.box_button_icon} />
                    )}
                    <div style={styles.box_button_label}>{action.label.length ? action.label : action_defaults[action.action_type].label}</div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'wrap'
      },
      box_button: {
        width: 120,
        height: 120,
        margin: '0 10px 20px',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer'
      },
      box_button_selected: {
        border: `2px solid ${Colors.GREEN.hex}`
      },
      box_button_image: {
        height: 40,
        width: 40,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      },
      box_button_icon: {
        fontSize: 40
      },
      box_button_label: {
        marginTop: 10,
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM
      }
    };
  };
}

QuoteActionModal.contextType = ProductContext;

export default QuoteActionModal;
