import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ProductContext } from 'alq/Context';

import Modal from 'shared/Modal';
// import { Button, Copy, Field, Input, Alert } from '@hexure/ui';
import Button from 'shared/Button';
import Input from 'shared/Input';
import Field from 'shared/Field';
import Alert from 'shared/Alert';
import Copy from 'shared/Copy';

import { Colors, FontSizes, FontWeights } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';
import FormUtils from 'utils/Form';

import { SA_BASE_URL } from 'config/App';

// Create a wrapper that filters out custom props (like "invalid")
const SafeInput = props => {
  // Remove any props that shouldn't be forwarded to the DOM.
  // For example, "invalid" is used for styling but we don't want it on a native input.
  const { invalid, ...rest } = props;
  return <Input {...rest} />;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
`;

const PdfPreview = styled.div.withConfig({
  shouldForwardProp: prop => !['isSelected', 'previewColor', 'previewImage'].includes(prop)
})`
  width: 400px;
  height: 280px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.isSelected ? props.previewColor : '#fff')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.previewImage || ''});
  position: relative;
`;

const Layouts = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  justify-items: center;
`;

const InfoContent = styled.div`
  width: 800px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Form = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  padding: 18px 18px 0;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fcfcfc;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

class ShareQuotesModal extends React.Component {
  static contextType = ProductContext;
  static propTypes = {
    action: PropTypes.oneOf(['download', 'email', 'text']),
    onBackClick: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    selectedQuotes: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);
    const agent = (context && context.agent) || {};
    const client = (context && context.client) || {};
    this.state = {
      loading: false,
      current_view: props.action ? 'enter_information' : 'delivery_method',
      pdf_url: null,
      color: (context && context.color) || '#000',
      errors: [],
      agent_email: agent.email || '',
      agent_first_name: agent.first_name || '',
      agent_last_name: agent.last_name || '',
      agent_name: `${agent.first_name || ''} ${agent.last_name || ''}`,
      agent_phone_number: agent.phone || '',
      agent_license_number: agent.insurance_license_number || '',
      client_first_name: client.first_name || '',
      client_last_name: client.last_name || '',
      client_name: `${client.first_name || ''} ${client.last_name || ''}`,
      message: 'Thank you for your interest in a life insurance policy! Take a look at your personalized insurance quotes.',
      to: '',
      phone_number: '',
      subject: '',
      pdf_action: props.action || 'text',
      template: 'default'
    };
  }

  _toggleView = current_view => {
    this.setState({
      current_view,
      errors: []
    });
  };

  _setPdfAction = pdf_action => {
    this.setState({ pdf_action });
  };

  _handleInputChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  _handleLayoutChange = template => {
    this.setState({ template });
  };

  _generatePdf = () => {
    const { selectedQuotes } = this.props;
    const { agent_email, agent_license_number, agent_phone_number, message, pdf_action, phone_number, subject, to, template, agent_first_name, agent_last_name, client_first_name, client_last_name } =
      this.state;
    const errors = [];
    const agent_full_name = `${agent_first_name} ${agent_last_name}`;
    const client_full_name = `${client_first_name} ${client_last_name}`;

    if (pdf_action === 'email') {
      if (!FormUtils._validateEmail(to)) errors.push('to');
      if (!FormUtils._validateEmail(agent_email)) errors.push('agent_email');
      if (subject.length < 4) errors.push('subject');
      if (message.length < 4) errors.push('message');
    }
    if (pdf_action === 'text') {
      if (!FormUtils._validatePhone(phone_number)) errors.push('phone_number');
      if (!message.length) errors.push('message');
    }
    if (errors.length) {
      this.setState({ errors });
    } else {
      this.setState({ loading: true, errors: [] });
      AlqApi.generateQuotesPdf({
        quote_guids: selectedQuotes,
        template,
        pdf_action,
        show_agent_info: true,
        agent_email,
        from: agent_email,
        agent_name: agent_full_name,
        agent_license_number,
        agent_phone_number,
        to,
        client_name: client_full_name,
        phone_number,
        subject,
        message
      }).then(response => {
        this.setState({
          current_view: 'success',
          pdf_url: response.data.url,
          loading: false
        });
      });
    }
  };

  _openPdf = () => {
    window.open(this.state.pdf_url);
  };

  render() {
    const { onClose, onBackClick } = this.props;
    const {
      agent_email,
      agent_license_number,
      agent_first_name,
      agent_last_name,
      agent_phone_number,
      client_first_name,
      client_last_name,
      current_view,
      errors,
      loading,
      message,
      pdf_action,
      phone_number,
      subject,
      to,
      template,
      color
    } = this.state;
    const styles = this.styles();
    const titles = {
      delivery_method: 'Select a Delivery Method',
      template: 'Select a PDF Layout',
      enter_information: 'Enter Information',
      success: ''
    };
    const buttons = {
      delivery_method: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: onClose,
          style: { margin: '0 10px 0 auto' },
          children: 'Cancel'
        },
        {
          onClick: () => this._toggleView('template'),
          children: 'Next'
        }
      ],
      template: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: () => (onBackClick ? onBackClick() : this._toggleView('delivery_method')),
          style: { margin: '0 10px 0 auto' },
          children: 'Back'
        },
        {
          onClick: () => this._toggleView('enter_information'),
          children: 'Next'
        }
      ],
      enter_information: [
        {
          color: '#e5e5e5',
          fontColor: '#999',
          onClick: () => (onBackClick ? onBackClick() : this._toggleView('template')),
          style: { margin: '0 10px 0 auto' },
          children: 'Back'
        },
        {
          disabled: loading,
          onClick: loading ? null : this._generatePdf,
          children: pdf_action === 'download' ? 'Download' : 'Send'
        }
      ]
    };
    const success_messages = {
      download: 'Your quote comparison PDF is ready. Click the button below to view and download it.',
      email: `Your quote comparison PDF has been delivered to ${to} on your behalf. Click the button below to view and download the PDF for your own records.`,
      text: `A link to your quote comparison PDF has been texted to ${FormUtils._formatAsPhone(phone_number)}. Click the button below to view and download the PDF for your own records.`
    };

    return (
      <Modal buttons={buttons[current_view]} loading={loading} maxWidth={1080} onClose={onClose} title={titles[current_view]}>
        {current_view === 'delivery_method' && (
          <div style={styles.content}>
            <div style={styles.delivery_method_wrapper}>
              <div
                onClick={() => this._setPdfAction('text')}
                style={{
                  ...styles.box_button,
                  ...(pdf_action === 'text' ? styles.box_button_selected : {})
                }}
              >
                <i className='mdi mdi-forum-outline' style={styles.box_button_icon} />
                <div style={styles.box_button_label}>Text</div>
              </div>
              <div
                onClick={() => this._setPdfAction('email')}
                style={{
                  ...styles.box_button,
                  ...(pdf_action === 'email' ? styles.box_button_selected : {})
                }}
              >
                <i className='mdi mdi-email-outline' style={styles.box_button_icon} />
                <div style={styles.box_button_label}>Email</div>
              </div>
              <div
                onClick={() => this._setPdfAction('download')}
                style={{
                  ...styles.box_button,
                  ...(pdf_action === 'download' ? styles.box_button_selected : {})
                }}
              >
                <i className='mdi mdi-file-download-outline' style={styles.box_button_icon} />
                <div style={styles.box_button_label}>Download</div>
              </div>
            </div>
          </div>
        )}

        {current_view === 'template' && (
          <Layouts>
            <Layout onClick={() => this._handleLayoutChange('default')} id='actions-demo-layout-report'>
              <Copy type='bold'>Detailed Comparison</Copy>
              <PdfPreview previewColor={color} previewImage={`${SA_BASE_URL}/ixn/images/classic.png`} isSelected={template === 'default'} id='actions-income-comparison-report' />
            </Layout>
            <Layout onClick={() => this._handleLayoutChange('clementine_condensed')} id='actions-features-layout-report'>
              <Copy type='bold'>Condensed Premium Comparison</Copy>
              <PdfPreview previewColor={color} previewImage={`${SA_BASE_URL}/ixn/images/clementine_condensed.png`} isSelected={template === 'clementine_condensed'} id='actions-features-report' />
            </Layout>
            <Layout onClick={() => this._handleLayoutChange('clementine_by_premium')} id='actions-bonus-layout-report'>
              <Copy type='bold'>Premium Comparison</Copy>
              <PdfPreview
                previewColor={color}
                previewImage={`${SA_BASE_URL}/ixn/images/clementine_by_premium.png`}
                isSelected={template === 'clementine_by_premium'}
                id='actions-bonus-comparison-report'
              />
            </Layout>
            <Layout onClick={() => this._handleLayoutChange('clementine_by_feature')} id='actions-accumulation-layout-report'>
              <Copy type='bold'>Feature Comparison</Copy>
              <PdfPreview
                previewColor={color}
                previewImage={`${SA_BASE_URL}/ixn/images/clementine_by_feature.png`}
                isSelected={template === 'clementine_by_feature'}
                id='actions-accumulation-comparison-report'
              />
            </Layout>
          </Layouts>
        )}

        {current_view === 'enter_information' && (
          <InfoContent>
            {errors.includes('api_error') && (
              <Alert
                description='An error occurred while trying to generate the PDF. If it continues to happen, please contact support.'
                title='Something went wrong'
                type='error'
                id='actions-error-message'
              />
            )}
            <Form>
              <Column>
                <Copy type='bold'>Agent Information</Copy>
                <FieldGroup>
                  <Row>
                    <Field label='First Name' style={{ width: 120 }} id='actions-info-agent-first-name'>
                      <SafeInput onChange={e => this._handleInputChange('agent_first_name', e)} readOnly={loading} value={agent_first_name} id='actions-info-agent-first-name-input' />
                    </Field>
                    <Field label='Last Name' style={{ flex: 1 }} id='actions-info-agent-last-name'>
                      <SafeInput onChange={e => this._handleInputChange('agent_last_name', e)} readOnly={loading} value={agent_last_name} id='actions-info-agent-last-name-input' />
                    </Field>
                  </Row>
                  <Field label='Email' required={pdf_action === 'email'} style={{ width: '100%' }} id='actions-info-agent-email'>
                    <SafeInput onChange={e => this._handleInputChange('agent_email', e)} readOnly={loading} value={agent_email} id='actions-info-agent-email-input' />
                  </Field>
                  <Field label='Phone' style={{ width: '100%' }} id='actions-info-agent-phone'>
                    <SafeInput format='phone' onChange={e => this._handleInputChange('agent_phone_number', e)} readOnly={loading} value={agent_phone_number} id='actions-info-agent-phone-input' />
                  </Field>
                  <Field label='License Number' style={{ width: '100%' }} id='actions-info-agent-license'>
                    <SafeInput onChange={e => this._handleInputChange('agent_license_number', e)} readOnly={loading} value={agent_license_number} id='actions-info-agent-license-input' />
                  </Field>
                </FieldGroup>
              </Column>
              <Column>
                <Copy type='bold'>Recipient Information</Copy>
                <FieldGroup>
                  <Row>
                    <Field label='First Name' style={{ width: 120 }} id='actions-recipient-client-first-name'>
                      <SafeInput onChange={e => this._handleInputChange('client_first_name', e)} readOnly={loading} value={client_first_name} id='actions-recipient-client-first-name-input' />
                    </Field>
                    <Field label='Last Name' style={{ flex: 1 }} id='actions-recipient-client-last-name'>
                      <SafeInput onChange={e => this._handleInputChange('client_last_name', e)} readOnly={loading} value={client_last_name} id='actions-recipient-client-last-name-input' />
                    </Field>
                  </Row>
                  {pdf_action === 'email' && (
                    <>
                      <Field label='Email' required style={{ width: '100%' }} id='actions-email-label'>
                        <SafeInput onChange={e => this._handleInputChange('to', e)} readOnly={loading} value={to} id='actions-email-input' />
                      </Field>
                      <Field label='Subject' required style={{ width: '100%' }} id='actions-subject-label'>
                        <SafeInput onChange={e => this._handleInputChange('subject', e)} readOnly={loading} value={subject} id='actions-subject-input' />
                      </Field>
                      <Field label='Message' required style={{ width: '100%' }} id='actions-message-label'>
                        <SafeInput
                          height={140}
                          onChange={e => this._handleInputChange('message', e)}
                          readOnly={loading}
                          style={{ width: '100%' }}
                          type='textarea'
                          value={message}
                          id='actions-message-input'
                        />
                      </Field>
                    </>
                  )}
                  {pdf_action === 'text' && (
                    <>
                      <Field label='Phone' required style={{ width: '100%' }} id='actions-text-phone-label'>
                        <SafeInput format='phone' onChange={e => this._handleInputChange('phone_number', e)} readOnly={loading} value={phone_number} id='actions-text-phone-input' />
                      </Field>
                      <Field label='Message' required style={{ width: '100%' }} id='actions-text-message-label'>
                        <SafeInput
                          height={140}
                          onChange={e => this._handleInputChange('message', e)}
                          readOnly={loading}
                          style={{ width: '100%' }}
                          type='textarea'
                          value={message}
                          id='actions-text-message-input'
                        />
                      </Field>
                    </>
                  )}
                </FieldGroup>
              </Column>
            </Form>
          </InfoContent>
        )}

        {current_view === 'success' && (
          <div style={styles.content}>
            <div style={styles.success_wrapper}>
              <div style={styles.success_title}>Success!</div>
              <div style={styles.success_description}>{success_messages[pdf_action]}</div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button inline onClick={this._openPdf} style={{ backgroundColor: color, borderColor: color }}>
                  View PDF
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }

  styles = () => {
    return {
      content: {
        padding: 40,
        minWidth: 600
      },
      delivery_method_wrapper: {
        display: 'flex',
        justifyContent: 'center'
      },
      box_button: {
        width: 120,
        height: 120,
        margin: '0 10px',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer'
      },
      box_button_selected: {
        border: `2px solid ${this.state.color}`
      },
      box_button_icon: {
        fontSize: 40
      },
      box_button_label: {
        marginTop: 10,
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM
      },
      template_wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      },
      pdf_button: {
        width: 480,
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      pdf_preview: {
        width: 450,
        height: 335,
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer',
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat',
        position: 'relative'
      },
      new: {
        position: 'absolute',
        top: -8,
        right: -6,
        fontSize: FontSizes.XSMALL,
        letterSpacing: 2,
        textTransform: 'uppercase',
        fontWeight: FontWeights.BOLD,
        color: '#fff',
        background: Colors.GREEN.hex,
        padding: '5px 8px',
        borderRadius: 3
      },
      success_wrapper: {
        textAlign: 'center',
        maxWidth: 600,
        margin: '0 auto'
      },
      success_title: {
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 20
      },
      success_description: {
        fontSize: FontSizes.MEDIUM,
        color: '#777',
        marginBottom: 40,
        lineHeight: '1.4em'
      }
    };
  };
}

ShareQuotesModal.contextType = ProductContext;
export default ShareQuotesModal;
