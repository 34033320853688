import React from 'react';
import styled from 'styled-components';

import { Colors } from 'constants/Clementine';

const H1 = styled.h1`
  color: ${Colors.BLACK};
  font-size: 30px;
  font-weight: ${props => (props.$bold ? '500' : '400')};
  line-height: 1.4em;
  font-family: 'Roboto Slab', Roboto, Helvetica, Arial, sans-serif;
  margin: ${props => props.$margin || '0px'};
  padding: ${props => props.$padding || '0px'};
  box-sizing: border-box;
`;

const H2 = styled.h2`
  color: ${Colors.BLACK};
  font-size: 24px;
  font-weight: ${props => (props.$bold ? '500' : '400')};
  line-height: 1.33em;
  font-family: 'Roboto Slab', Roboto, Helvetica, Arial, sans-serif;
  margin: ${props => props.$margin || '0px'};
  padding: ${props => props.$padding || '0px'};
  box-sizing: border-box;
`;

const H3 = styled.h3`
  color: ${Colors.BLACK};
  font-size: 18px;
  font-weight: ${props => (props.$bold ? '500' : '400')};
  line-height: 1.33em;
  font-family: 'Roboto Slab', Roboto, Helvetica, Arial, sans-serif;
  margin: ${props => props.$margin || '0px'};
  padding: ${props => props.$padding || '0px'};
  box-sizing: border-box;
`;

const Heading = ({ bold, children, margin, padding, type, dataItemid, ...accessibleProps }) => {
  const baseId = dataItemid || 'heading';

  switch (type) {
    case 'secondary': {
      return (
        <H2 $bold={bold} $margin={margin} $padding={padding} {...accessibleProps} data-itemid={`${baseId}-h2`}>
          {children}
        </H2>
      );
    }
    case 'tertiary': {
      return (
        <H3 $bold={bold} $margin={margin} $padding={padding} {...accessibleProps} data-itemid={`${baseId}-h3`}>
          {children}
        </H3>
      );
    }
    default: {
      return (
        <H1 $bold={bold} $margin={margin} $padding={padding} {...accessibleProps} data-itemid={`${baseId}-h1`}>
          {children}
        </H1>
      );
    }
  }
};

Heading.defaultProps = {
  bold: false,
  type: 'primary'
};

export default Heading;
