import React from 'react';
import styled from 'styled-components';
import { Colors, FontSizes } from 'constants/Clementine';

const StyledComponent = styled.p`
  color: ${props => Colors[props.$color || 'BLACK']};
  font-size: ${props => (props.$type === 'small' ? FontSizes.SMALL : FontSizes.DEFAULT)};
  line-height: ${props => (props.$type === 'small' ? '1.5em' : '1.6em')};
  letter-spacing: ${props => (props.$type === 'small' ? '1px' : '0px')};
  font-weight: ${props => (props.$type === 'bold' ? '500' : '400')};
  font-style: ${props => (props.$type === 'italic' ? 'italic' : 'normal')};
  text-decoration: ${props => (['underline', 'line-through'].includes(props.$type) ? props.$type : 'none')};
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  margin: ${props => props.$margin || '0px'};
  padding: ${props => props.$padding || '0px'};
  text-align: ${props => props.$align || 'left'};
  box-sizing: border-box;
`;

const Copy = ({ children, align = '', margin = '', padding = '', type = 'default', color = 'BLACK', dataItemid, ...accessibleProps }) => {
  const baseId = dataItemid || 'copy';

  return (
    <StyledComponent $align={align} $color={color} $margin={margin} $padding={padding} $type={type} data-itemid={`${baseId}-styled-component`} {...accessibleProps}>
      {children}
    </StyledComponent>
  );
};

Copy.defaultProps = {
  type: 'default'
};

export default Copy;
