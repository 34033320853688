import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiAlertOctagonOutline, mdiAlertOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js';

import Heading from 'shared/Heading';
import Copy from 'shared/Copy';

import { Colors, EditableTheme, FontSizes, FontStyles } from 'constants/Clementine';

const Wrapper = styled.div`
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  border-left-width: 4px;
  box-shadow: 0px 4px 12px -6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  gap: ${props => (props.$small ? '8px' : '20px')};
  padding: ${props => (props.$small ? '11px' : '20px')};
  box-sizing: border-box;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const Action = styled.div`
  color: ${props => props.theme.PRIMARY_COLOR.Hex};
  font-size: ${FontSizes.DEFAULT};
  font-family: ${FontStyles.DEFAULT};
  font-weight: 500;
  cursor: pointer;
  margin-top: 6px;
`;

Action.defaultProps = { theme: EditableTheme };

const Alert = ({ action, type = 'info', title, description, small = false, dataItemid, ...accessibleProps }) => {
  const theme = useContext(ThemeContext) || EditableTheme;
  const baseId = dataItemid || 'alert';
  const type_mapping = {
    info: {
      color: theme.PRIMARY_COLOR.Hex,
      icon: mdiInformationOutline
    },
    error: {
      color: Colors.RED.Hex,
      icon: mdiAlertOctagonOutline
    },
    warning: {
      color: Colors.YELLOW.Hex,
      icon: mdiAlertOutline
    },
    success: {
      color: Colors.GREEN.Hex,
      icon: mdiCheckboxMarkedCircleOutline
    }
  };

  return (
    <Wrapper {...accessibleProps} $small={small} data-itemid={`${baseId}-wrapper`} style={{ borderLeftColor: type_mapping[type].color }}>
      <StyledIcon color={type_mapping[type].color} data-itemid={`${baseId}-icon`} path={type_mapping[type].icon} size={small ? '20px' : '30px'} />
      <div data-itemid={`${baseId}-content`}>
        {title && !small ? (
          <Heading bold data-itemid={`${baseId}-title`} margin='2px 0 0 0' type='tertiary'>
            {title}
          </Heading>
        ) : null}
        {description ? (
          <Copy data-itemid={`${baseId}-description`} margin={small ? '' : '6px 0 0 0 !important'}>
            {description}
          </Copy>
        ) : null}
        {action && !small ? (
          <Action data-itemid={`${baseId}-action`} onClick={action.onClick}>
            {action.label}
          </Action>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default Alert;
