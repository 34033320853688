import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Numeral from 'numeral';
import { intersection, sortBy } from 'lodash';

import AgeField from 'alq/AgeField';
import AgentLoginForm from 'alq/AgentLoginForm';
import HealthAnalyzerModal from 'alq/HealthAnalyzerModal';
import NeedsAnalysisModal from 'alq/NeedsAnalysisModal';
import QuoteResults from 'alq/QuoteResults';

import Button from 'shared/Button';
import ButtonMenuModal from 'shared/ButtonMenuModal';
import FieldGroup from 'shared/FieldGroup';
import Guide from 'shared/Guide';
import Checklist from 'shared/Checklist';
import Input from 'shared/Input';
import Field from 'shared/Field';
import Legal from 'shared/Legal';
import Loader from 'shared/Loader';
import Modal from 'shared/Modal';
import MultiSelect from 'shared/MultiSelect';
import PowerSelect from 'shared/PowerSelect';
import Select from 'shared/Select';
import ZeroState from 'shared/ZeroState';

import { Colors, FontSizes, FontWeights, FontFamilies, HealthCategories, States } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';
import FormUtils from 'utils/Form';

import { ProductContext } from 'alq/Context';
import { ThemeContext } from 'shared/ThemeContext';

import { SA_BASE_URL } from 'config/App';
import Notification from '../components/Notifications/Toasts';

window.IXN = {};

const QUOTER_CONFIG = window.IXN_QUOTER_CONFIG;
const ixn_auth = Cookies.getJSON('ixn') || {};

class AgencyQuoter extends React.Component {
  static propTypes = {
    onToggleVersion: PropTypes.func
  };

  constructor(props) {
    super(props);

    const health_analyzer_enabled = true;

    this.state = {
      loading_alq: true,
      loading_carriers: true,
      loading_quotes: false,
      errors: [],
      criteria_errors: [],
      visible_modal: false,
      visible_age_type: 'DOB',
      visible_health_type: health_analyzer_enabled ? 'classes' : 'analyzer',
      show_criteria: true,
      show_tour: false,
      tour_index: 0,
      disable_quoter: false,
      health_analyzer_enabled,
      required_check: true,

      alq: {},
      carriers: [],
      product_types: [],
      product_features: [],
      guides: [],
      color: Colors.BLUE.hex,
      minimum_face_amount: 0,
      maximum_face_amount: 1000000000,

      agent_current_view: 'login',
      agent: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        insurance_license_number: ''
      },
      client: {
        first_name: '',
        last_name: ''
      },
      quote_params: [],
      health_analyzer_data: {
        height_feet: '5',
        height_inch: '7',
        weight: 160,
        tobacco_type: 'None',
        tobacco_last_used: 'Never',
        no_of_cigars: 0,
        marijuana: 'false',
        marijuana_type: 'None',
        marijuana_last_used: 'Never',
        no_of_marijuana: 0
      },
      quote_data: {
        current_age: 50,
        nearest_age: 51,
        date_of_birth: Moment().subtract(50, 'years').toDate(),
        gender: 'Male',
        tobacco: false,
        state: 'AL',
        health_categories: [],
        face_amounts: [],
        flat_extra: '0.00',
        carrier_ids: [],
        product_types: [],
        riders: [],
        child_rider_units: '0',
        product_features: []
      },
      ran_quote_data: null,

      quotes: null,
      windowWidth: window.innerWidth,
      isVive: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize);

    AlqApi.loadBgaQuoter()
      .then(({ data }) => {
        const cookied_info = Cookies.getJSON('ixn_clementine_alq') || {};
        const qc_client = QUOTER_CONFIG.client || {}; // This is "deprecated", end user should be using quote_data instead.
        const qc_quote_data = QUOTER_CONFIG.quote_data || {};
        const cookied_quote_data = cookied_info.quote_data || {};

        const allowed_carrier_ids = QUOTER_CONFIG.carrier_ids || data.carrier_ids || [];
        const carrier_ids = intersection(allowed_carrier_ids, qc_quote_data.carrier_ids || cookied_quote_data.carrier_ids || []);

        const term_products = QUOTER_CONFIG.term_products || data.term_products || [];
        const gul_products = QUOTER_CONFIG.gul_products || data.guaranteed_life_products || [];
        const rop_products = QUOTER_CONFIG.rop_products || data.return_of_premium_products || [];
        const other_products = QUOTER_CONFIG.other_products || data.other_products || [];
        const allowed_product_types = QUOTER_CONFIG.product_types || [...term_products, ...gul_products, ...rop_products, ...other_products];
        const product_types = intersection(allowed_product_types, qc_client.product_types || qc_quote_data.product_types || cookied_quote_data.product_types || []);

        const minimum_face_amount = QUOTER_CONFIG.minimum_face_amount || data.minimum_face_amount || 0;
        const maximum_face_amount = data.maximum_face_amount || 1000000000;
        const qc_dob = qc_client.dob_year ? Moment(`${qc_client.dob_year}-${qc_client.dob_month}-${qc_client.dob_day}`).toDate() : null;
        const qc_qd_dob = qc_quote_data.dob_year ? Moment(`${qc_quote_data.dob_year}-${qc_quote_data.dob_month}-${qc_quote_data.dob_day}`).toDate() : null;

        const quote_data = {
          current_age: qc_client.current_age || qc_quote_data.current_age || 50,
          nearest_age: qc_client.nearest_age || qc_quote_data.nearest_age || 51,
          date_of_birth: qc_dob || qc_qd_dob || Moment().subtract(50, 'years').toDate(),
          gender: qc_client.gender || qc_quote_data.gender || 'Male',
          tobacco: qc_client.tobacco || qc_quote_data.tobacco || false,
          state: qc_client.state || qc_quote_data.state || cookied_quote_data.state || data.default_state || data.states[0] || 'AL',
          visible_health_type: qc_quote_data.visible_health_type || this.state.visible_health_type,
          health_categories: qc_client.health_categories || qc_quote_data.health_categories || cookied_quote_data.health_categories || [],
          face_amounts:
            qc_quote_data.face_amounts ||
            (qc_quote_data.face_amount
              ? [qc_quote_data.face_amount]
              : cookied_quote_data.face_amounts || (data.default_face_amount ? [data.default_face_amount < minimum_face_amount ? minimum_face_amount : data.default_face_amount] : [])),
          flat_extra: qc_client.flat_extra || qc_quote_data.flat_extra || cookied_quote_data.flat_extra || '0.00',
          carrier_ids,
          product_types,
          riders: qc_client.riders || qc_quote_data.riders || [],
          child_rider_units: `${qc_client.child_rider_units || qc_quote_data.child_rider_units || 0}`,
          product_features: []
        };

        this.setState(
          {
            loading_alq: false,
            visible_age_type: QUOTER_CONFIG.visible_age_type || qc_client.age_type || cookied_info.visible_age_type || 'DOB',

            alq: data,
            product_types: allowed_product_types,
            color: QUOTER_CONFIG.color || QUOTER_CONFIG.secondary_color || data.theme_options.secondary_color || Colors.BLUE.hex,
            minimum_face_amount,
            maximum_face_amount,

            agent: QUOTER_CONFIG.agent || cookied_info.agent || this.state.agent,
            client: {
              first_name: qc_client.first_name || '',
              last_name: qc_client.last_name || ''
            },
            health_analyzer_data: Object.assign({}, this.state.health_analyzer_data, qc_quote_data.health_analyzer_data || {}),
            quote_params: QUOTER_CONFIG.quote_params || [],
            quote_data
          },
          () => {
            this._validateCriteria();
          }
        );

        AlqApi.loadCarriers(allowed_carrier_ids, data.is_beta).then(carriers => {
          this.setState({
            loading_carriers: false,
            carriers
          });
        });

        AlqApi.loadProductFeatures().then(product_features => {
          this.setState({
            product_features
          });
        });

        AlqApi.loadUnderwritingGuides(allowed_carrier_ids).then(guides => {
          this.setState({
            guides
          });
        });

        if (ixn_auth.id) {
          AlqApi.loadMember(ixn_auth.id).then(response => {
            this.setState(
              {
                agent: response.data
              },
              () => {
                this._validateCriteria();
              }
            );
          });
        }

        if (QUOTER_CONFIG.embed_context === 'fire_light' && QUOTER_CONFIG.product_cusips && QUOTER_CONFIG.product_cusips.length) {
          AlqApi.loadProducts(data.product_ids).then(response => {
            const product_ids_with_valid_cusips = response.data.filter(product => product.cusip === null || QUOTER_CONFIG.product_cusips.includes(product.cusip)).map(product => product.id);

            this.setState(prev_state => {
              return {
                alq: {
                  ...prev_state.alq,
                  product_ids: product_ids_with_valid_cusips
                }
              };
            });
          });
        }

        window.IXN.get_quoter_config = () => {
          const { current_age, date_of_birth, nearest_age } = this.state.quote_data;
          const ages_from_dob = FormUtils._getAgesFromDob(date_of_birth);

          return {
            agent: this.state.agent,
            client: this.state.client,
            quote_data: {
              ...this.state.quote_data,
              date_of_birth: date_of_birth ? Moment(date_of_birth).format('YYYY-MM-DD') : null,
              current_age: current_age ? current_age : ages_from_dob.calculated_current_age,
              nearest_age: nearest_age ? nearest_age : ages_from_dob.calculated_nearest_age
            }
          };
        };

        window.IXN.update_quoter_config = data => {
          this.setState({
            loading_alq: true
          });

          const new_state = {
            loading_alq: false
          };

          // AGENT
          if (data.first_name || data.last_name || data.phone || data.email || data.license_number || data.applicint_trusted_code) {
            new_state.agent = {
              first_name: data.first_name,
              last_name: data.last_name,
              phone: data.phone,
              email: data.email,
              insurance_license_number: data.license_number,
              applicint_trusted_code: data.applicint_trusted_code
            };
          } else if (data.agent) {
            new_state.agent = data.agent;
          }

          // CLIENT
          if (data.client) {
            new_state.client = {
              first_name: data.client.first_name || '',
              last_name: data.client.last_name || ''
            };
          }

          // QUOTE DATA
          if (data.quote_data || data.client) {
            const quote_data = this.state.quote_data;
            const passed_client = data.client || {};
            const passed_quote_data = data.quote_data || {};
            const passed_client_dob = passed_client.dob_year ? Moment(`${passed_client.dob_year}-${passed_client.dob_month}-${passed_client.dob_day}`).toDate() : null;
            const quote_data_dob = passed_quote_data.dob_year ? Moment(`${passed_quote_data.dob_year}-${passed_quote_data.dob_month}-${passed_quote_data.dob_day}`).toDate() : null;

            new_state.quote_data = {
              current_age: passed_client.current_age || passed_quote_data.current_age || 50,
              nearest_age: passed_client.nearest_age || passed_quote_data.nearest_age || 51,
              date_of_birth: passed_client_dob || quote_data_dob || Moment().subtract(50, 'years').toDate(),
              gender: passed_client.gender || passed_quote_data.gender || 'Male',
              tobacco: passed_client.tobacco || passed_quote_data.tobacco || false,
              state: passed_client.state || passed_quote_data.state || quote_data.state,

              health_categories: passed_quote_data.health_categories || quote_data.health_categories,
              face_amounts:
                passed_quote_data.face_amounts ||
                (passed_quote_data.face_amount
                  ? [passed_quote_data.face_amount < this.state.minimum_face_amount ? this.state.minimum_face_amount : passed_quote_data.face_amount]
                  : quote_data.face_amounts),
              flat_extra: passed_quote_data.flat_extra || quote_data.flat_extra,
              carrier_ids: passed_quote_data.carrier_ids || quote_data.carrier_ids,
              product_types: passed_quote_data.product_types || quote_data.product_types,
              riders: passed_quote_data.riders || quote_data.riders,
              child_rider_units: `${passed_quote_data.child_rider_units || quote_data.riders}`,
              product_features: quote_data.product_features
            };
          }

          this.setState(new_state, () => {
            this._validateCriteria();
          });
        };

        window.IXN.disableQuoter = () => {
          this.setState({
            disable_quoter: true
          });
        };

        window.IXN.enableQuoter = () => {
          this.setState({
            disable_quoter: false
          });
        };
      })
      .catch(error => {
        if (error.response && error.response.data.errors) {
          this.setState({
            loading_alq: false,
            loading_carriers: false,
            errors: error.response.data.errors
          });
        } else {
          this.setState({
            loading_alq: false,
            loading_carriers: false,
            errors: [{ title: 'An unknown error occurred.' }]
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.quote_data.carrier_ids !== this.state.quote_data.carrier_ids) {
      const viveCarrierIds = [28, 49, 69, 99, 111, 140, 1500, 1700, 1795, 1826, 1839];
      const isVive = this.state.quote_data.carrier_ids.length > 0 && this.state.quote_data.carrier_ids.every(id => viveCarrierIds.includes(id));

      if (this.state.isVive !== isVive) {
        this.setState({ isVive });
      }
    }
  }

  _handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  _toggleCriteria = show_criteria => {
    this.setState({
      show_criteria,
      show_tour: false
    });
  };

  _toggleModal = name => {
    this.setState({
      visible_modal: name
    });
  };

  _toggleAgentLogin = type => {
    this.setState({
      agent_current_view: type
    });
  };

  _toggleTour = show_tour => {
    this.setState({
      show_tour,
      tour_index: 0,
      show_criteria: true,
      visible_modal: null
    });

    if (!show_tour) {
      Cookies.set('ixn_hide_tour', true, { domain: window.location.hostname });
    }
  };

  _handleTourStateChange = state => {
    if (state.action === 'skip' || state.action === 'reset' || state.status === 'finished') {
      this._toggleTour(false);
    }

    if (state.status === 'finished') {
      this.setState({
        visible_modal: 'tour_end'
      });
    }
  };

  _handleInputChange = (group, name, e) => {
    this.setState(
      {
        [group]: Object.assign({}, this.state[group], {
          [name]: e.target.value
        })
      },
      () => {
        this._handleRequiredCheck();
      }
    );
  };

  _handleSelectChange = (group, name, e) => {
    this.setState(
      {
        [group]: Object.assign({}, this.state[group], {
          [name]: e.target.value
        })
      },
      () => {
        this._handleRequiredCheck();
      }
    );
  };

  _handleFieldChange = (group, name, value) => {
    this.setState(
      {
        [group]: Object.assign({}, this.state[group], {
          [name]: value
        })
      },
      () => {
        this._handleRequiredCheck();
      }
    );
  };
  _handleRequiredCheck = () => {
    const { quote_data, visible_age_type, visible_health_type } = this.state;
    const criteria_errors = [];
    const hc_count = quote_data.health_categories.length;
    const fa_count = quote_data.face_amounts.length;
    const c_count = quote_data.carrier_ids.length;
    const pt_count = quote_data.product_types.length;
    const estimated_quote_count = hc_count * c_count * pt_count * fa_count;

    if (quote_data.current_age) {
      if (quote_data.current_age < 1) {
        criteria_errors.push('current_age');
      }
    }

    if (visible_age_type === 'DOB') {
      if (!quote_data.date_of_birth || !Moment(quote_data.date_of_birth, 'YYYY-MM-DD', true).isValid()) {
        criteria_errors.push('date_of_birth');
      }
    }

    if (visible_age_type === 'Age' && !quote_data.current_age) {
      criteria_errors.push('current_age');
    }

    if (visible_health_type === 'classes' && hc_count < 1) {
      criteria_errors.push('health_categories');
    }

    if (fa_count < 1) {
      criteria_errors.push('face_amounts');
    }

    if (c_count < 1) {
      criteria_errors.push('carrier_ids');
    }

    if (pt_count < 1) {
      criteria_errors.push('product_types');
    }

    if (estimated_quote_count > 200) {
      criteria_errors.push('quotes');
    }

    this.setState({
      criteria_errors
    });

    return !criteria_errors.length;
  };

  _handleChecklistChange = (group, name, selected) => {
    this.setState(
      {
        [group]: Object.assign({}, this.state[group], {
          [name]: selected
        })
      },
      () => {
        this._handleRequiredCheck();
      }
    );
  };

  _handleTobaccoChange = e => {
    const tobacco = e.target.value;

    this.setState({
      quote_data: { ...this.state.quote_data, tobacco },
      health_analyzer_data: {
        ...this.state.health_analyzer_data,
        tobacco_last_used: tobacco === 'true' ? 'Within the last year' : 'Never',
        tobacco_type: tobacco === 'true' ? 'Cigarettes' : 'None',
        no_of_cigars: 0
      }
    });
  };

  _handleFaceAmountsChange = face_amounts => {
    this.setState(
      {
        quote_data: Object.assign({}, this.state.quote_data, {
          face_amounts: face_amounts ? face_amounts.map(fa => parseInt(`${fa.value}`.replace(/[^0-9]/g, ''), 10)) : []
        })
      },
      () => {
        this._handleRequiredCheck();
      }
    );
  };

  _handleAgentLogin = agent => {
    this.setState(
      {
        visible_modal: null,
        agent
      },
      () => {
        this._validateCriteria();
      }
    );
  };

  _handleAgentLogout = () => {
    AlqApi.logout().then(() => {
      Cookies.remove('ixn', { domain: window.location.hostname });

      this.setState(
        {
          agent: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            insurance_license_number: ''
          }
        },
        () => {
          this._validateCriteria();
        }
      );
    });
  };

  _addCalculatedFaceAmount = face_amount => {
    const cleaned_face_amount = parseInt(`${face_amount}`.replace(/[^0-9]/g, ''), 10);
    this._toggleModal(null);
    this.setState(
      prev_state => {
        const prev_face_amounts = prev_state.quote_data.face_amounts;
        const next_face_amounts = prev_face_amounts.length < 3 ? [...prev_face_amounts, cleaned_face_amount] : [...prev_face_amounts.slice(0, 2), cleaned_face_amount];

        return { quote_data: { ...prev_state.quote_data, face_amounts: next_face_amounts } };
      },
      () => {
        this._handleRequiredCheck();
      }
    );
  };

  _toggleHealthTypeField = visible_health_type => {
    this.setState({ visible_health_type }, this._handleRequiredCheck);
  };

  _handleApplyHealthAnalyzerData = data => {
    const { tobacco, ...health_analyzer_data } = data;

    this.setState({
      visible_modal: null,
      quote_data: {
        ...this.state.quote_data,
        tobacco
      },
      health_analyzer_data
    });
  };

  _validateCriteria = () => {
    const { agent, alq } = this.state;
    const criteria_errors = [];

    alq.required_agent_info.forEach(field_name => {
      if (!agent[field_name] || agent[field_name].length < 2) {
        criteria_errors.push(field_name);
      }
    });

    this.setState({
      criteria_errors
    });

    return !criteria_errors.length;
  };

  _getQuotes = () => {
    if (!this.state.isVive) {
      this.state.quote_;
    }

    if (this._validateCriteria() && this._handleRequiredCheck()) {
      const cookied_info = Cookies.getJSON('ixn_clementine_alq') || {};
      const { client } = this.state;
      const { agent } = this.state;
      const requests = this.state.quote_data.face_amounts.map(face_amount => {
        const quote_data = Object.assign({}, this.state.quote_data);

        quote_data.product_ids = QUOTER_CONFIG.product_ids || this.state.alq.product_ids || [];

        if (this.state.visible_age_type === 'DOB') {
          quote_data.date_of_birth = Moment(quote_data.date_of_birth).format('MM-DD-YYYY');
          delete quote_data.current_age;
          delete quote_data.nearest_age;
        } else {
          delete quote_data.date_of_birth;
          quote_data.nearest_age = quote_data.nearest_age || quote_data.current_age;
        }

        if (this.state.visible_health_type === 'analyzer') {
          const updated_health_analyzer_data = { ...this.state.health_analyzer_data };
          if (!this.state.isVive) {
            updated_health_analyzer_data.marijuana = 'false';
            updated_health_analyzer_data.marijuana_type = 'None';
            updated_health_analyzer_data.marijuana_last_used = 'Never';
            updated_health_analyzer_data.no_of_marijuana = 0;

            this.setState(prevState => ({
              health_analyzer_data: updated_health_analyzer_data,
              quote_data: {
                ...prevState.quote_data,
                health_analyzer_data: updated_health_analyzer_data
              }
            }));
          }
          quote_data.health_analyzer_data = updated_health_analyzer_data;
        }

        quote_data.adb_rider = quote_data.riders.includes('adb_rider');
        quote_data.wop_rider = quote_data.riders.includes('wop_rider');
        delete quote_data.riders;

        quote_data.face_amount = face_amount;
        delete quote_data.face_amounts;

        quote_data.client = client;
        quote_data.agent = agent;

        if ((agent.first_name && agent.first_name.length) || (agent.last_name && agent.last_name.length)) {
          quote_data.agent.name = `${agent.first_name || ''} ${agent.last_name || ''}`;
        }

        return AlqApi.getQuotes(quote_data);
      });

      const in_tour = this.state.show_tour;

      this.setState({
        loading_quotes: true,
        show_tour: false
      });

      Cookies.set(
        'ixn_clementine_alq',
        Object.assign({}, cookied_info, {
          visible_age_type: this.state.visible_age_type,
          agent: this.state.agent,
          quote_data: Object.assign({}, this.state.quote_data)
        }),
        { domain: window.location.hostname }
      );

      Promise.all(requests).then(responses => {
        const quotes = responses.map(r => r.data).flat();

        this.setState({
          quotes,
          ran_quote_data: Object.assign({}, this.state.quote_data, { visible_age_type: this.state.visible_age_type }),
          loading_quotes: false,
          show_criteria: false,
          show_tour: in_tour,
          tour_index: 6
        });

        if (QUOTER_CONFIG.quote_actions && QUOTER_CONFIG.quote_actions.length) {
          if (QUOTER_CONFIG.embed_context === 'fire_light') {
            AlqApi.loadProducts(quotes.map(q => q.product_id)).then(response => {
              const quotes_with_cusips = this._addCusipsToQuotes(response.data, quotes);
              this._addEmbedConfigQuoteActions(quotes_with_cusips);
              this.setState({ quotes: quotes_with_cusips });
            });
          } else {
            this._addEmbedConfigQuoteActions(quotes);
          }
        }
      });
    }
  };

  _addCusipsToQuotes = (products, quotes) => {
    return quotes.map(quote => {
      const quoted_product = products.find(product => quote.product_id === product.id);

      return { ...quote, product_cusip: quoted_product.cusip };
    });
  };

  _addEmbedConfigQuoteActions = quotes => {
    const embed_config_quote_actions = [];

    QUOTER_CONFIG.quote_actions.forEach(quote_action => {
      const enabled_quote_guids = [];

      quotes.forEach(quote => {
        if (quote_action.should_show(quote)) {
          enabled_quote_guids.push(quote.guid);
        }
      });

      embed_config_quote_actions.push({ ...quote_action, enabled_quote_guids });
    });

    this.setState(prev_state => {
      return { alq: { ...prev_state.alq, embed_config_quote_actions } };
    });
  };

  render() {
    const {
      agent,
      alq,
      carriers,
      client,
      color,
      criteria_errors,
      disable_quoter,
      errors,
      guides,
      health_analyzer_data,
      health_analyzer_enabled,
      loading_alq,
      loading_quotes,
      minimum_face_amount,
      maximum_face_amount,
      product_features,
      product_types,
      quote_data,
      quotes,
      ran_quote_data,
      show_criteria,
      show_tour,
      tour_index,
      visible_age_type,
      visible_health_type,
      visible_modal,
      windowWidth
    } = this.state;
    const runQuoteCheck = quote_data.carrier_ids.length > 0 && quote_data.product_types.length > 0 && quote_data.face_amounts.length > 0 && quote_data.health_categories.length > 0;
    const styles = this.styles();
    const isMobile = windowWidth <= 900;

    const mobile_criteria_content = {
      padding: 30,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    };

    const mobile_criteria_column = {
      width: '100%'
    };

    const responsive_criteria_content = isMobile ? mobile_criteria_content : styles.criteria_content;
    const responsive_criteria_column = isMobile ? mobile_criteria_column : styles.criteria_column;
    const responsive_results_style = isMobile ? styles.responsive_results_summary : styles.results_summary;
    const responsive_ran_quote_style = isMobile ? styles.responsive_ran_quote_criteria : styles.ran_quote_criteria;
    const responsive_agent_style = isMobile ? styles.responsive_summary_agent : styles.summary_agent;
    const responsive_coverage_style = isMobile ? styles.responsive_summary_coverage : styles.summary_coverage;

    return (
      <ThemeContext.Provider value={{ color }}>
        <ProductContext.Provider value={{ alq, agent, client, color, ran_quote_data, product_features, quoter_config: QUOTER_CONFIG, handleAgentLogin: this._handleAgentLogin }}>
          {/* <Notification color={this.state.color} message='Welcome to Agency Life Quoter' /> */}
          <div className='ixn-agency-quoter'>
            {loading_alq || loading_quotes ? (
              <Loader />
            ) : (
              <React.Fragment>
                {errors.length ? (
                  <ZeroState
                    icon='mdi-alert-circle-outline'
                    message={
                      <div id='error-message'>
                        {errors[0].title} Please contact IXN Support at{' '}
                        <a href='mailto:support@ixntech.com' style={{ color: Colors.BLUE.hex }}>
                          support@ixntech.com
                        </a>
                        .
                      </div>
                    }
                  />
                ) : (
                  <div id='container' style={styles.container}>
                    {show_criteria ? (
                      <div id='criteria-container' style={styles.criteria_container}>
                        {visible_modal === 'tour' ? (
                          <Modal
                            id='tour-modal'
                            buttons={[
                              {
                                color: '#e5e5e5',
                                fontColor: Colors.GRAY.hex,
                                children: 'Maybe Later',
                                onClick: this._toggleTour.bind(null, false),
                                style: { marginLeft: 'auto', marginRight: 10 }
                              },
                              {
                                children: 'Start Tour',
                                onClick: () => {
                                  this._toggleTour(true);
                                }
                              }
                            ]}
                            maxWidth={600}
                            onClose={this._toggleTour.bind(null, false)}
                            title='Welcome Back!'
                          >
                            <div id='tour-modal-content' style={styles.tool_modal_content}>
                              <img src={`${SA_BASE_URL}/ixn/sand/alq/images/feedback.png`} style={{ width: '100%', marginBottom: 20 }} />
                              <div id='tour-intro' style={styles.tour_intro}>
                                We've heard your feedback and have made improvements to the new look and feel. This tour will walk you through the key changes and features. Don't have time right now?
                                No problem. You can start the tour again from the Toolbox icon found in the bottom left corner of the quoter.
                              </div>
                            </div>
                          </Modal>
                        ) : null}

                        {visible_modal === 'tour_end' ? (
                          <Modal
                            id='tour-end-modal'
                            buttons={[
                              {
                                children: 'Close',
                                onClick: () => {
                                  this._toggleModal(null);
                                },
                                style: { marginLeft: 'auto' }
                              }
                            ]}
                            maxWidth={600}
                            onClose={this._toggleModal.bind(null, null)}
                            title='Tour Completed'
                          >
                            <div id='tour-end-modal-content' style={styles.tool_modal_content}>
                              <div id='tour-end-intro' style={styles.tour_intro}>
                                Thank you for taking the time to walk through the new quoter with us. We hope these changes will create a smoother and faster quoting experience for you. Our products
                                are only as good as the feedback we get, so please keep it coming!
                                <div style={{ marginTop: 20, fontStyle: 'italic' }}>The IXN Product Team</div>
                              </div>
                            </div>
                          </Modal>
                        ) : null}

                        <div id='responsive-criteria-content' style={responsive_criteria_content}>
                          <FieldGroup id='proposed-insured' label='Proposed Insured' style={responsive_criteria_column}>
                            <Field id='field-first-name' label='First Name'>
                              <Input id='first-name' onChange={this._handleInputChange.bind(null, 'client', 'first_name')} readOnly={disable_quoter} value={client.first_name} />
                            </Field>
                            <Field id='field-last-name' label='Last Name'>
                              <Input id='last-name' onChange={this._handleInputChange.bind(null, 'client', 'last_name')} readOnly={disable_quoter} value={client.last_name} />
                            </Field>
                            <AgeField
                              id='age-field'
                              data={{ current_age: quote_data.current_age, nearest_age: quote_data.nearest_age, date_of_birth: quote_data.date_of_birth }}
                              disabled={disable_quoter}
                              invalid={criteria_errors.includes('date_of_birth') || criteria_errors.includes('current_age')}
                              onFieldChange={data => {
                                this.setState(
                                  {
                                    quote_data: Object.assign({}, quote_data, data)
                                  },
                                  this._handleRequiredCheck
                                );
                              }}
                              onTypeChange={show_dob => {
                                this.setState({ visible_age_type: show_dob ? 'DOB' : 'Age' }, this._handleRequiredCheck);
                              }}
                              required={true}
                              showDob={visible_age_type === 'DOB'}
                            />
                            <div id='gender-tobacco' style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Field id='gender' label='Gender' style={{ width: '49%' }}>
                                <Select
                                  id='gender-select'
                                  onChange={this._handleSelectChange.bind(null, 'quote_data', 'gender')}
                                  options={[
                                    { label: 'Male', value: 'Male' },
                                    { label: 'Female', value: 'Female' }
                                  ]}
                                  readOnly={disable_quoter}
                                  value={quote_data.gender}
                                />
                              </Field>
                              <Field id='tobacco-user' label='Tobacco User' style={{ width: '49%' }}>
                                <Select
                                  id='tobacco-user-select'
                                  onChange={this._handleTobaccoChange}
                                  options={[
                                    { label: 'Yes', value: 'true' },
                                    { label: 'No', value: 'false' }
                                  ]}
                                  readOnly={disable_quoter || visible_health_type === 'analyzer'}
                                  value={quote_data.tobacco}
                                />
                              </Field>
                            </div>
                            <Field id='state' label='State'>
                              <Select
                                id='state-select'
                                onChange={this._handleSelectChange.bind(null, 'quote_data', 'state')}
                                options={alq.states.length ? sortBy(alq.states).map(s => ({ value: s, label: States[s].label })) : Object.values(States).map(s => ({ value: s.value, label: s.label }))}
                                readOnly={disable_quoter}
                                value={quote_data.state}
                              />
                            </Field>
                            <div id='ixn-tour-data-backed-criteria'>
                              {health_analyzer_enabled ? (
                                <>
                                  {visible_health_type === 'analyzer' ? (
                                    <Field
                                      id='health-analyzer'
                                      action={{
                                        label: 'Use Health Classes',
                                        onClick: this._toggleHealthTypeField.bind(null, 'classes')
                                      }}
                                      label='Health Analyzer'
                                    >
                                      <div id='health-analyzer-input' onClick={this._toggleModal.bind(null, 'health_analyzer')} style={styles.faux_input}>
                                        <div id='health-analyzer-label' style={styles.faux_input_label}>
                                          {`${health_analyzer_data.height_feet}' ${health_analyzer_data.height_inch}", ${health_analyzer_data.weight} lbs, ${quote_data.tobacco === 'true' ? health_analyzer_data.tobacco_type : 'No Tobacco'}`}
                                        </div>
                                        <i className='mdi mdi-pencil' style={styles.faux_input_icon} />
                                      </div>
                                    </Field>
                                  ) : (
                                    <Field
                                      id='health-classes'
                                      action={{
                                        label: 'Use Health Analyzer',
                                        onClick: this._toggleHealthTypeField.bind(null, 'analyzer')
                                      }}
                                      invalid={criteria_errors.includes('health_categories')}
                                      label='Health Classes'
                                      tooltipError={criteria_errors.includes('health_categories')}
                                      tooltipErrorMsg='At least 1 health class is required'
                                    >
                                      <div id='multi-select-health-categories'>
                                        <MultiSelect
                                          id='multi-healh-categories'
                                          disabled={disable_quoter}
                                          invalid={criteria_errors.includes('health_categories')}
                                          onChange={this._handleChecklistChange.bind(null, 'quote_data', 'health_categories')}
                                          optionHeight={150}
                                          options={HealthCategories.all.map(hc => ({ value: hc }))}
                                          required={true}
                                          selected={quote_data.health_categories}
                                          selectAll={true}
                                        />
                                      </div>
                                    </Field>
                                  )}
                                </>
                              ) : (
                                <Field
                                  id='health-classes-no-analyzer'
                                  invalid={criteria_errors.includes('health_categories')}
                                  label='Health Classes'
                                  tooltipError={criteria_errors.includes('health_categories')}
                                  tooltipErrorMsg='At least 1 health class is required'
                                >
                                  <MultiSelect
                                    id='multi-healh-categories-no-analyzer'
                                    disabled={disable_quoter}
                                    invalid={criteria_errors.includes('health_categories')}
                                    onChange={this._handleChecklistChange.bind(null, 'quote_data', 'health_categories')}
                                    optionHeight={150}
                                    options={HealthCategories.all.map(hc => ({ value: hc }))}
                                    required={true}
                                    selected={quote_data.health_categories}
                                  />
                                </Field>
                              )}
                            </div>
                          </FieldGroup>

                          <FieldGroup id='product' label='Product' style={responsive_criteria_column}>
                            <Field
                              id='product-types'
                              invalid={criteria_errors.includes('product_types')}
                              label='Product Types'
                              tooltipError={criteria_errors.includes('product_types')}
                              tooltipErrorMsg='At least 1 product type is required'
                            >
                              <MultiSelect
                                id='multi-product-type'
                                disabled={disable_quoter}
                                displayCount={2}
                                invalid={criteria_errors.includes('product_types')}
                                onChange={this._handleChecklistChange.bind(null, 'quote_data', 'product_types')}
                                options={product_types.map(pt => ({ value: pt }))}
                                required={true}
                                selected={quote_data.product_types}
                              />
                            </Field>

                            <div id='ixn-tour-pre-filled-criteria'>
                              <Field
                                id='carriers'
                                invalid={criteria_errors.includes('carrier_ids')}
                                label='Carriers'
                                tooltipError={criteria_errors.includes('carrier_ids')}
                                tooltipErrorMsg='At least 1 carrier is required'
                              >
                                <MultiSelect
                                  id='multi-carrier-select'
                                  disabled={disable_quoter}
                                  displayCount={3}
                                  invalid={criteria_errors.includes('carrier_ids')}
                                  onChange={this._handleChecklistChange.bind(null, 'quote_data', 'carrier_ids')}
                                  options={carriers.map(c => ({ label: c.name, value: c.id }))}
                                  required={true}
                                  selectAll={true}
                                  selected={quote_data.carrier_ids}
                                />
                              </Field>
                            </div>

                            <div id='ixn-tour-multiple-face-amounts'>
                              <Field
                                id='face-amounts'
                                action={{
                                  label: (
                                    <div>
                                      {criteria_errors.includes('face_amounts') && (
                                        <span style={styles.error_msg}>
                                          At least 1 required
                                          <i className='mdi mdi-alert' style={styles.error_icon} />
                                        </span>
                                      )}
                                      <span onClick={this._toggleModal.bind(null, 'needs_analysis')} style={styles.fieldgroup_action}>
                                        {' Calculate Needs'}
                                      </span>
                                    </div>
                                  )
                                }}
                                label='Face Amount(s)'
                              >
                                <PowerSelect
                                  id='face-amount-type'
                                  creatable={true}
                                  disabled={disable_quoter}
                                  formatCreateLabel={inputValue => `Press Enter to add: ${Numeral(inputValue).format('$0,0')}`}
                                  isMulti={true}
                                  newOptionCheck={inputValue => {
                                    const value = parseInt(inputValue.replace(/[^0-9]/g, ''), 10);
                                    const min_value = minimum_face_amount || 0;
                                    const max_value = maximum_face_amount;

                                    return value <= max_value && value >= min_value && !quote_data.face_amounts.includes(value) && quote_data.face_amounts.length < 3;
                                  }}
                                  noOptionsMessage={
                                    quote_data.face_amounts.length < 3
                                      ? `Enter a face amounts between ${Numeral(minimum_face_amount).format('$0,0')} and ${Numeral(maximum_face_amount).format('$0,0')}...`
                                      : 'Only 3 Face Amounts Allowed.'
                                  }
                                  onChange={this._handleFaceAmountsChange}
                                  placeholder='Start typing...'
                                  required={true}
                                  value={quote_data.face_amounts.map(fa => ({ value: fa, label: Numeral(fa).format('$0,0') }))}
                                />
                              </Field>
                            </div>
                            <Field id='flat-extra' label='Flat Extra (per thousand)'>
                              <Input
                                id='flat-extra-unit'
                                format='currency_decimal'
                                onChange={this._handleInputChange.bind(null, 'quote_data', 'flat_extra')}
                                readOnly={disable_quoter}
                                value={quote_data.flat_extra}
                              />
                            </Field>
                            <Checklist
                              id='riders'
                              disabled={disable_quoter}
                              onChange={this._handleChecklistChange.bind(null, 'quote_data', 'riders')}
                              options={[
                                { value: 'adb_rider', label: 'Accidental Death Benefit' },
                                { value: 'wop_rider', label: 'Waiver of Premium' }
                              ]}
                              selected={quote_data.riders}
                            />
                            <Field id='child-rider-units' label='Child Rider Units' style={{ marginTop: 20 }}>
                              <Select
                                id='child-rider-units-select'
                                onChange={this._handleSelectChange.bind(null, 'quote_data', 'child_rider_units')}
                                options={[
                                  { value: 0 },
                                  { value: 1 },
                                  { value: 2 },
                                  { value: 3 },
                                  { value: 4 },
                                  { value: 5 },
                                  { value: 6 },
                                  { value: 7 },
                                  { value: 8 },
                                  { value: 9 },
                                  { value: 10 },
                                  { value: 11 },
                                  { value: 12 },
                                  { value: 13 },
                                  { value: 14 },
                                  { value: 15 },
                                  { value: 16 },
                                  { value: 17 },
                                  { value: 18 },
                                  { value: 19 },
                                  { value: 20 },
                                  { value: 21 },
                                  { value: 22 },
                                  { value: 23 },
                                  { value: 24 },
                                  { value: 25 }
                                ]}
                                readOnly={disable_quoter}
                                value={quote_data.child_rider_units}
                              />
                            </Field>
                          </FieldGroup>

                          <FieldGroup
                            id='fieldgroup-agent'
                            action={
                              agent.id ? (
                                <div id='agent-logout' onClick={this._handleAgentLogout} style={styles.fieldgroup_action}>
                                  Logout
                                </div>
                              ) : (
                                <div
                                  id='ixn-tour-ixn-account-login'
                                  onClick={this._toggleModal.bind(null, 'agent_login')}
                                  style={{ textAlign: 'center', fontSize: FontSizes.SMALL, cursor: 'pointer' }}
                                >
                                  Have an account? <span style={styles.fieldgroup_action}>Login</span>
                                </div>
                              )
                            }
                            label='Agent'
                            style={responsive_criteria_column}
                          >
                            <Field
                              id='field-first-name'
                              action={
                                criteria_errors.includes('first_name')
                                  ? {
                                      label: (
                                        <div id='error-first-name' style={styles.error_msg}>
                                          Required <i className='mdi mdi-alert' style={styles.error_icon} />
                                        </div>
                                      )
                                    }
                                  : null
                              }
                              label='First Name'
                            >
                              <Input
                                id='input-first-name'
                                invalid={criteria_errors.includes('first_name')}
                                onChange={this._handleInputChange.bind(null, 'agent', 'first_name')}
                                readOnly={disable_quoter}
                                required={alq.required_agent_info.includes('first_name')}
                                value={agent.first_name}
                              />
                            </Field>
                            <Field
                              id='field-last-name'
                              action={
                                criteria_errors.includes('last_name')
                                  ? {
                                      label: (
                                        <div id='error-last-name' style={styles.error_msg}>
                                          Required <i className='mdi mdi-alert' style={styles.error_icon} />
                                        </div>
                                      )
                                    }
                                  : null
                              }
                              label='Last Name'
                            >
                              <Input
                                id='input-last-name'
                                invalid={criteria_errors.includes('last_name')}
                                onChange={this._handleInputChange.bind(null, 'agent', 'last_name')}
                                readOnly={disable_quoter}
                                required={alq.required_agent_info.includes('last_name')}
                                value={agent.last_name}
                              />
                            </Field>
                            <Field
                              id='field-email'
                              action={
                                criteria_errors.includes('email')
                                  ? {
                                      label: (
                                        <div id='error-email' style={styles.error_msg}>
                                          Required <i className='mdi mdi-alert' style={styles.error_icon} />
                                        </div>
                                      )
                                    }
                                  : null
                              }
                              label='Email'
                            >
                              <Input
                                id='input-email'
                                invalid={criteria_errors.includes('email')}
                                onChange={this._handleInputChange.bind(null, 'agent', 'email')}
                                readOnly={disable_quoter}
                                required={alq.required_agent_info.includes('email')}
                                value={agent.email}
                              />
                            </Field>
                            <Field
                              id='field-phone'
                              action={
                                criteria_errors.includes('phone')
                                  ? {
                                      label: (
                                        <div id='error-phone' style={styles.error_msg}>
                                          Required <i className='mdi mdi-alert' style={styles.error_icon} />
                                        </div>
                                      )
                                    }
                                  : null
                              }
                              label='Phone'
                            >
                              <Input
                                id='input-phone'
                                format='phone'
                                invalid={criteria_errors.includes('phone')}
                                onChange={this._handleInputChange.bind(null, 'agent', 'phone')}
                                readOnly={disable_quoter}
                                required={alq.required_agent_info.includes('phone')}
                                value={agent.phone}
                              />
                            </Field>
                            <Field
                              id='field-license-number'
                              action={
                                criteria_errors.includes('insurance_license_number')
                                  ? {
                                      label: (
                                        <div id='error-license-number' style={styles.error_msg}>
                                          Required <i className='mdi mdi-alert' style={styles.error_icon} />
                                        </div>
                                      )
                                    }
                                  : null
                              }
                              label='License Number'
                            >
                              <Input
                                id='input-license-number'
                                invalid={criteria_errors.includes('insurance_license_number')}
                                onChange={this._handleInputChange.bind(null, 'agent', 'insurance_license_number')}
                                readOnly={disable_quoter}
                                required={alq.required_agent_info.includes('insurance_license_number')}
                                value={agent.insurance_license_number}
                              />
                            </Field>
                          </FieldGroup>
                        </div>
                        <div id='criteria-action-bar' style={styles.criteria_action_bar}>
                          <div id='criteria-action-bar-column-1' style={styles.criteria_action_bar_column}>
                            <Button color='#e5e5e5' disabled={disable_quoter} fontColor='#444' id='ixn-tour-toolbox' onClick={this._toggleModal.bind(null, 'tools')} style={styles.icon_btn}>
                              <i className='mdi mdi-tools' style={styles.icon_btn_icon} />
                            </Button>
                            {ran_quote_data ? (
                              <Button color='#e5e5e5' disabled={disable_quoter} fontColor='#444' id='ixn-tour-back-to-results' onClick={this._toggleCriteria.bind(null, false)}>
                                Back to Previous Results
                              </Button>
                            ) : null}
                          </div>

                          <div id='criteria-action-bar-column-2' style={styles.criteria_action_bar_column}>
                            {criteria_errors.includes('quotes') ? (
                              <div id='error-quotes' style={styles.error_msg}>
                                Too many options selected. Adjust criteria. <i className='mdi mdi-alert' style={styles.error_icon} />
                              </div>
                            ) : null}
                            <Button
                              id={disable_quoter || criteria_errors.length > 0 ? 'ixn-tour-disabled-button' : 'ixn-tour-enabled-button'}
                              disabled={disable_quoter || criteria_errors.length > 0 || !runQuoteCheck}
                              onClick={this._getQuotes}
                              style={{ marginLeft: 15 }}
                            >
                              Run Quote
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div id='results' style={styles.results}>
                        <div id='ixn-tour-summary-info' style={responsive_results_style}>
                          <div id='responsive-ran-quote-style' style={responsive_ran_quote_style}>
                            <div id='summary-client' style={styles.summary_client}>
                              <div id='summary-client-name' style={styles.summary_client_name}>
                                {client.first_name || 'Proposed'} {client.last_name || 'Insured'}
                              </div>
                              <div id='summary-client-info' style={styles.summary_client_info}>
                                <span id='summary-info-gender' style={styles.summary_info_item}>
                                  {quote_data.gender}
                                </span>
                                <span style={styles.summary_divider} />
                                <span id='summary-info-age' style={styles.summary_info_item}>
                                  {visible_age_type === 'Age' ? quote_data.current_age : Moment(quote_data.date_of_birth).format('MM/DD/YYYY')}
                                </span>
                                <span style={styles.summary_divider} />
                                <span id='summary-info-state' style={styles.summary_info_item}>
                                  {quote_data.state}
                                </span>
                                <span style={styles.summary_divider} />
                                <span id='summary-info-health' style={styles.summary_info_item}>
                                  {visible_health_type === 'analyzer'
                                    ? `${health_analyzer_data.height_feet}' ${health_analyzer_data.height_inch}", ${health_analyzer_data.weight} lbs, ${quote_data.tobacco === 'true' ? health_analyzer_data.tobacco_type : 'No Tobacco'}`
                                    : quote_data.health_categories.join(', ')}
                                </span>
                              </div>
                            </div>
                            <div>
                              <div id='responsive-coverage-style' style={responsive_coverage_style}>
                                <span id='summary-info-label-coverage' style={styles.summary_info_label}>
                                  Coverage:
                                </span>
                                <span id='summary-info-item-coverage' style={styles.summary_info_item}>
                                  {quote_data.face_amounts.map(fa => Numeral(fa).format('$0,0')).join(', ')}
                                </span>
                                <span style={styles.summary_divider} />
                                <span id='summary-info-item-child-rider' style={styles.summary_info_item}>
                                  {quote_data.child_rider_units} Child Rider Units
                                </span>
                                <span style={styles.summary_divider} />
                                <span id='summary-info-item-flat-extra' style={styles.summary_info_item}>
                                  {Numeral(quote_data.flat_extra).format('$0.00')} Flat Extra
                                </span>
                              </div>
                              <div id='responsive-agent-style' style={responsive_agent_style}>
                                <span id='summary-info-label-agent' style={styles.summary_info_label}>
                                  Agent:
                                </span>
                                <span id='summary-info-item-agent' style={styles.summary_info_item}>
                                  {agent.first_name} {agent.last_name}
                                </span>
                                <span style={styles.summary_divider} />
                                <span id='summary-info-item-agent-email' style={styles.summary_info_item}>
                                  {agent.email}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Button id='ixn-tour-new-quote' onClick={this._toggleCriteria.bind(null, true)} style={styles.new_quote_btn}>
                            <div style={styles.new_quote_btn_content}>
                              <i className='mdi mdi-calculator' style={styles.new_quote_btn_icon} />
                              {isMobile ? (
                                <div>New Quote</div>
                              ) : (
                                <div>
                                  New
                                  <br />
                                  Quote
                                </div>
                              )}
                            </div>
                          </Button>
                        </div>

                        {quotes.length ? <QuoteResults quotes={quotes} /> : <ZeroState icon='mdi-magnify' message='No matching products found. Try adjusting the criteria above.' />}
                      </div>
                    )}

                    {visible_modal === 'tools' ? (
                      <ButtonMenuModal
                        id='modal-tools'
                        buttons={[
                          {
                            id: 'button-product-tour',
                            onClick: this._toggleModal.bind(null, 'tour'),
                            label: 'Quoter Tour',
                            icon: 'mdi-map-search-outline'
                          },
                          {
                            id: 'button-needs-analysis',
                            onClick: this._toggleModal.bind(null, 'needs_analysis'),
                            label: 'Needs Analysis',
                            icon: 'mdi-shield-home-outline'
                          },
                          {
                            id: 'button-underwriting-guides',
                            onClick: this._toggleModal.bind(null, 'underwriting_guides'),
                            label: 'Underwriting Guides',
                            icon: 'mdi-file-document-outline'
                          }
                        ]}
                        onClose={this._toggleModal.bind(null, null)}
                        title='Toolbox'
                      />
                    ) : null}

                    {visible_modal === 'needs_analysis' && (
                      <NeedsAnalysisModal
                        id='modal-needs-analysis'
                        agent_id={this.state.agent && this.state.agent.id}
                        maximum_face_amount={maximum_face_amount}
                        minimum_face_amount={minimum_face_amount}
                        onBackToTools={this._toggleModal.bind(null, 'tools')}
                        onClose={this._toggleModal.bind(null, null)}
                        onQuoteWithCoverage={this._addCalculatedFaceAmount}
                      />
                    )}

                    {visible_modal === 'health_analyzer' ? (
                      <HealthAnalyzerModal
                        id='modal-health-analyzer'
                        data={{ tobacco: quote_data.tobacco, ...health_analyzer_data }}
                        isVive={this.state.isVive}
                        disableQuoter={disable_quoter}
                        onApply={this._handleApplyHealthAnalyzerData}
                        onClose={this._toggleModal}
                      />
                    ) : null}

                    {visible_modal === 'underwriting_guides' ? (
                      <Modal
                        id='modal-underwriting-guides'
                        buttons={[
                          {
                            color: '#e5e5e5',
                            fontColor: '#444',
                            children: 'Back To Tools',
                            onClick: this._toggleModal.bind(null, 'tools')
                          }
                        ]}
                        maxWidth={400}
                        onClose={this._toggleModal.bind(null, null)}
                        title='Carrier Underwriting Guides'
                      >
                        <div id='underwriting-guides-content' style={styles.tool_modal_content}>
                          {guides
                            .filter(g => g.states.includes(quote_data.state))
                            .sort(function (a, b) {
                              const carrier_a = carriers.find(c => c.id === a.carrier_id);
                              const carrier_b = carriers.find(c => c.id === b.carrier_id);

                              if (carrier_a && carrier_a.name.toLowerCase() > carrier_b && carrier_b.name.toLowerCase()) {
                                return 1;
                              } else {
                                return -1;
                              }
                            })
                            .map(g => {
                              const pdf = g.pdf_file || {};
                              const carrier = carriers.find(c => g.carrier_id === c.id);

                              return carrier ? (
                                <a href={pdf.url} key={carrier.id} id={`guide-${carrier.id}`} style={styles.guide} target='_blank'>
                                  <i className='mdi mdi-file-pdf-outline' style={styles.icon} />
                                  {carrier.name}
                                </a>
                              ) : null;
                            })}
                        </div>
                      </Modal>
                    ) : null}

                    {visible_modal === 'agent_login' ? (
                      <Modal
                        title={this.state.agent_current_view === 'login' ? 'Login with your IXN Account' : 'Create an IXN Account'}
                        id='modal-agent-login'
                        maxWidth={600}
                        onClose={this._toggleModal.bind(null, null)}
                      >
                        <AgentLoginForm agentView={this.state.agent_current_view} onChangeAgentView={this._toggleAgentLogin} onLogin={this._handleAgentLogin} />
                      </Modal>
                    ) : null}

                    {show_tour ? (
                      <Guide
                        id='guide-tour'
                        continuous={true}
                        defaultIndex={tour_index}
                        onChange={this._handleTourStateChange}
                        run={show_tour}
                        steps={[
                          {
                            target: '#ixn-tour-data-backed-criteria',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Data-Backed Criteria</div>
                                <div style={styles.tour_body}>
                                  We've analyzed years of quote data to show you the most popular and relevent options for health, product types, and carriers. But don't worry, you can still view all
                                  the options by clicking the "View All" buttons below each option list.
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'right-start',
                            customCallback: state => {
                              if (state.action === 'next' && state.lifecycle === 'complete' && criteria_errors.includes('health_categories')) {
                                this._handleChecklistChange('quote_data', 'health_categories', [HealthCategories.primary[0]]);
                              }
                            }
                          },
                          {
                            target: '#ixn-tour-pre-filled-criteria',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Pre-filled Criteria</div>
                                <div style={styles.tour_body}>
                                  Some information like state, product types, carriers, face amounts, and agent information doesn't change much from quote to quote. We store this information in a
                                  cookie on your browser and then pre-fill it the next time you run a quote to give you a jump start.
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'left-start',
                            customCallback: state => {
                              if (state.action === 'next' && state.lifecycle === 'complete' && criteria_errors.includes('product_types')) {
                                this._handleChecklistChange('quote_data', 'product_types', ['20 Year Term']);
                              }

                              if (state.action === 'next' && state.lifecycle === 'complete' && criteria_errors.includes('carrier_ids')) {
                                this._handleChecklistChange(
                                  'quote_data',
                                  'carrier_ids',
                                  carriers.map(c => c.id)
                                );
                              }
                            }
                          },
                          {
                            target: '#ixn-tour-multiple-face-amounts',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Multiple Face Amounts</div>
                                <div style={styles.tour_body}>
                                  You can now run up to 3 face amounts at one time. Type a face amount, then hit "Enter" or "Tab" to save it. When you get to the results, you'll be able to group them
                                  by the face amounts you entered here.
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'left-start',
                            customCallback: state => {
                              if (state.action === 'next' && state.lifecycle === 'complete' && criteria_errors.includes('face_amounts')) {
                                this._handleFaceAmountsChange([{ value: '250000' }]);
                              }
                            }
                          },
                          {
                            target: '#ixn-tour-ixn-account-login',
                            content: (
                              <div>
                                <div style={styles.tour_header}>IXN Account Login</div>
                                <div style={styles.tour_body}>This optional feature allows you to log into your IXN Agent Account to pre-fill your agent information.</div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'left-start',
                            customCallback: state => {
                              if (state.action === 'next' && state.lifecycle === 'complete') {
                                const agent_info = {};

                                if (criteria_errors.includes('first_name')) {
                                  agent_info.first_name = 'Demo';
                                }

                                if (criteria_errors.includes('last_name')) {
                                  agent_info.last_name = 'Agent';
                                }

                                if (criteria_errors.includes('email')) {
                                  agent_info.email = 'demo_agent@ixn.tech';
                                }

                                if (criteria_errors.includes('phone')) {
                                  agent_info.phone = '5555555555';
                                }

                                if (criteria_errors.includes('insurance_license_number')) {
                                  agent_info.insurance_license_number = '1234567890';
                                }

                                this.setState(
                                  {
                                    agent: Object.assign({}, agent, agent_info)
                                  },
                                  () => {
                                    this._validateCriteria();
                                  }
                                );
                              }
                            }
                          },
                          {
                            target: '#ixn-tour-toolbox',
                            content: (
                              <div>
                                <div style={styles.tour_header}>The Toolbox</div>
                                <div style={styles.tour_body}>This is where you can go to find additional tools like Underwriting Guides, a Needs Analysis Calculator, helpful guides, and more!</div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'right-end'
                          },
                          {
                            target: '#ixn-tour-disabled-button',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Proactive Validation</div>
                                <div style={styles.tour_body}>
                                  This button will be disabled until you have enough criteria selected to run a quote. Required criteria will be marked with a{' '}
                                  <span style={styles.error_msg}>
                                    Red message and alert icon <i className='mdi mdi-alert' style={styles.error_icon} />
                                  </span>
                                  .
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'left-end',
                            customCallback: state => {
                              if (state.action === 'next' && state.lifecycle === 'complete') {
                                this._getQuotes();
                              }
                            }
                          },
                          {
                            target: '#ixn-tour-summary-info',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Summary Information</div>
                                <div style={styles.tour_body}>
                                  This area will display information you selected on the initial screen. In order to keep this information concise, we've excluded some information that is easily
                                  visible in the results (like product types and carriers).
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'left-start',
                            hideBackButton: true
                          },
                          {
                            target: '',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Quick Search</div>
                                <div style={styles.tour_body}>
                                  We've added this convenience tool to allow you to search the results by carrier or product name. Simply start typing and the results will automatically begin to
                                  filter themselves.
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'left-start'
                          },
                          {
                            target: '#ixn-tour-filters',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Filters</div>
                                <div style={styles.tour_body}>
                                  Click this icon to adjust how your results are displayed. This includes the ability to sort by premiums, face amount, carrier name; group by product type, face
                                  amount, health category; and filter by premium.
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'left-start'
                          },
                          {
                            target: '.ixn-quote-card:first-child .ixn-quote-card--show-details',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Quote Details</div>
                                <div style={styles.tour_body}>
                                  Click this button to view more details related to the quote. This includes a new list of product features and a revamped forms section that gives you access to all
                                  the carrier's forms for this product.
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'top'
                          },
                          {
                            target: '#ixn-tour-new-quote',
                            content: (
                              <div>
                                <div style={styles.tour_header}>Start a New Quote</div>
                                <div style={styles.tour_body}>
                                  When you're ready to run a new quote, click this button. You'll be taken back to the initial screen where you can adjust the quote criteria and run a new quote. This
                                  time it will also include a button to return to your previous results.
                                </div>
                              </div>
                            ),
                            disableBeacon: true,
                            placement: 'top'
                          }
                        ]}
                      />
                    ) : null}
                  </div>
                )}
              </React.Fragment>
            )}

            <div style={styles.footer}>
              <Legal color={this.state.color} onUiSwitch={this.props.onToggleVersion} />
            </div>
          </div>
        </ProductContext.Provider>
      </ThemeContext.Provider>
    );
  }

  styles = () => {
    return {
      container: {
        position: 'relative'
      },
      results: {
        width: '100%',
        minHeight: 550,
        position: 'relative'
      },
      tool_modal_content: {
        padding: 40
      },
      guide: {
        display: 'block',
        lineHeight: '28px',
        fontSize: FontSizes.MEDIUM,
        color: this.state.color,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none'
      },
      icon: {
        color: Colors.GRAY.hex,
        fontSize: 20,
        marginRight: 4
      },
      quotes_loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10,
        background: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      footer: {
        marginTop: 20
      },
      select_all: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '4px 0',
        cursor: 'pointer'
      },
      select_all_label: {
        fontSize: FontSizes.REGULAR,
        fontWeight: FontWeights.REGULAR,
        lineHeight: '1.3em'
      },
      select_all_input: {
        fontSize: 20,
        marginLeft: 10
      },

      criteria_container: {
        background: '#f9f9f9',
        border: '1px solid #e5e5e5',
        borderRadius: 4
      },
      criteria_content: {
        padding: 30,
        display: 'flex',
        justifyContent: 'space-between'
      },
      criteria_column: {
        width: '31%'
      },
      view_all_btn: {
        marginTop: 6
      },
      fieldgroup_action: {
        fontSize: FontSizes.SMALL,
        cursor: 'pointer',
        color: this.state.color
      },
      criteria_action_bar: {
        padding: 20,
        background: '#f5f5f5',
        borderTop: '1px solid #e5e5e5',
        display: 'flex',
        justifyContent: 'space-between'
      },
      criteria_action_bar_column: {
        display: 'flex',
        alignItems: 'center'
      },
      view_all_modal: {
        padding: '10px 40px 40px',
        overflow: 'auto',
        height: 500,
        maxHeight: 'calc(100vh - 200px)'
      },
      error_msg: {
        fontSize: FontSizes.SMALL,
        color: Colors.RED.hex,
        position: 'relative',
        paddingLeft: 18,
        fontWeight: FontWeights.MEDIUM
      },
      error_icon: {
        fontSize: 16,
        position: 'absolute',
        left: -1,
        top: -2
      },
      icon_btn: {
        width: 42,
        marginRight: 5,
        padding: 0,
        position: 'relative'
      },
      icon_btn_icon: {
        fontSize: 20
      },
      icon_badge: {
        position: 'absolute',
        top: -3,
        right: -3,
        height: 10,
        width: 10,
        borderRadius: 10,
        background: Colors.RED.hex,
        boxShadow: `0px 0px 0px 4px rgba(${Colors.RED.rgb}, 0.2)`
      },
      results_summary: {
        display: 'flex',
        marginBottom: 20,
        alignItems: 'stretch'
      },
      responsive_results_summary: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
      },
      ran_quote_criteria: {
        background: '#f9f9f9',
        border: '1px solid #e5e5e5',
        borderRadius: 4,
        marginRight: 10,
        flex: 1,
        padding: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      responsive_ran_quote_criteria: {
        background: '#f9f9f9',
        border: '1px solid #e5e5e5',
        borderRadius: 4,
        marginBottom: 10,
        flex: 1,
        padding: 15,
        display: 'flex',
        flexDirection: 'column'
      },
      summary_client: {
        marginBottom: '10px'
      },
      summary_client_name: {
        fontSize: FontSizes.LARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 5
      },
      summary_coverage: {
        marginBottom: 8,
        textAlign: 'right',
        marginTop: 2
      },
      responsive_summary_coverage: {
        marginBottom: 8,
        marginTop: 2
      },
      summary_agent: {
        marginTop: 8,
        textAlign: 'right'
      },
      responsive_summary_agent: {
        marginTop: 8
      },
      summary_info_item: {
        fontSize: FontSizes.SMALL,
        color: '#777'
      },
      summary_info_label: {
        fontSize: FontSizes.SMALL,
        fontWeight: FontWeights.MEDIUM,
        color: Colors.GRAY.hex,
        marginRight: 6,
        textTransform: 'uppercase'
      },
      summary_divider: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: 3,
        height: 3,
        borderRadius: 3,
        background: '#999',
        margin: '0 6px'
      },
      new_quote_btn: {
        height: 'auto',
        flexShrink: 0,
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      new_quote_btn_content: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1',
        textAlign: 'left'
      },
      new_quote_btn_icon: {
        fontSize: 30
      },

      tour_header: {
        fontFamily: FontFamilies.ACCENT,
        fontSize: FontSizes.XLARGE,
        fontWeights: FontWeights.MEDIUM,
        marginBottom: 20,
        color: Colors.GRAY.hex
      },
      tour_body: {
        fontFamily: FontFamilies.MAIN,
        fontSize: FontSizes.REGULAR,
        fontWeight: FontWeights.REGULAR,
        lineHeight: '1.7em',
        color: Colors.GRAY.hex
      },
      tour_intro: {
        fontSize: 15,
        lineHeight: '1.6em',
        textAlign: 'center'
      },

      faux_input: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 40,
        borderRadius: 4,
        border: '1px solid #ccc',
        cursor: 'pointer',
        background: '#fff',
        padding: 10
      },
      faux_input_label: {
        fontSize: 14,
        lineHeight: '40px',
        color: Colors.GRAY.hex
      },
      faux_input_icon: {
        fontSize: 20,
        color: Colors.BLUE.hex
      }
    };
  };
}

ReactDOM.render(<AgencyQuoter />, document.getElementById('ixn-agency-quoter'));
