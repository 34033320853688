import {
  ANICO_EMBED_TOKEN,
  ANICO_TEST_EMBED_TOKEN,
  PACLIFE_EMBED_TOKEN,
  PACLIFE_TEST_EMBED_TOKEN,
  PROTECTIVE_EMBED_TOKEN,
  PROTECTIVE_TEST_EMBED_TOKEN,
  SBLI_EMBED_TOKEN,
  SBLI_TEST_EMBED_TOKEN
} from 'config/App';
import { Colors, FontSizes, FontWeights } from 'constants/Clementine';
import { cleanQuote, getRedirectUrl } from 'utils/FormIntegrations';

import AlqApi from 'utils/AlqApi';
import AmbestInfo from 'shared/AmbestInfo';
import ApplicintModal from 'alq/ApplicintModal';
import Button from 'shared/Button';
// import { CUSTOM_ACTIONS } from 'constants/App';
import FirelightEappModal from 'alq/FirelightEappModal';
import FirelightEticketModal from 'alq/FirelightEticketModal';
import LifedxEticketModal from 'alq/LifedxEticketModal';
import Loader from 'shared/Loader';
import Modal from 'shared/Modal';
import Moment from 'moment';
import NmbTermEticketModal from 'alq/nmb_term_eticket/Index';
import NmbWholeLifeEticketModal from 'alq/nmb_wl_eticket/Index';
import Numeral from 'numeral';
import OpenUrlModal from 'alq/OpenUrlModal';
import PostToUrlModal from 'alq/PostToUrlModal';
import { ProductContext } from 'alq/Context';
import PropTypes from 'prop-types';
import PsgEappModal from 'alq/PsgEappModal';
import QuoteActionModal from 'alq/QuoteActionModal';
import QuoteForms from 'alq/QuoteForms';
import QuotePremiums from 'alq/QuotePremiums';
import React from 'react';
import RequestFormsModal from 'alq/RequestFormsModal';
import ShareQuotesModal from 'alq/ShareQuotesModal';
import ZeroState from 'shared/ZeroState';
// import PdfActions from '../shared/PdfActions';

class QuoteCard extends React.Component {
  static propTypes = {
    customAction: PropTypes.string,
    onSelect: PropTypes.func,
    productFeatures: PropTypes.array,
    quote: PropTypes.object,
    selected: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      product: null,
      ambest_record: null,
      forms: null,
      form_tags: null,

      show_details: false,
      active_details: 'features',
      loading: false,
      hovering: false,
      modal: null,
      action: null,
      standard_packet_error: false,

      anico_eticket_guid: null,
      firelight_eticket: null,
      firelight_eapp: null,
      psg_eapp: null,
      paclife_eticket_guid: null,
      protective_eticket_guid: null,
      nmb_eticket_guid: null,
      sbli_eticket_guid: null
    };
  }

  componentDidMount() {
    this._loadProduct();
  }

  _setEticket = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  _setFirelightEticket = firelight_eticket => {
    this.setState({ firelight_eticket });
  };

  _setFirelightEapp = firelight_eapp => {
    this.setState({ firelight_eapp });
  };

  _setPsgEapp = psg_eapp => {
    this.setState({ psg_eapp });
  };

  _toggleDetails = () => {
    this.setState({
      show_details: !this.state.show_details
    });
  };

  _toggleContent = (active_details, loadContent) => {
    this.setState({ active_details });

    if (loadContent) {
      loadContent();
    }
  };

  _toggleHover = hovering => {
    this.setState({ hovering });
  };

  _toggleModal = modal => {
    this.setState({ modal });
  };

  _loadProduct = () => {
    if (!this.state.product) {
      this.setState({
        loading: true
      });

      AlqApi.loadProduct(this.props.quote.product_id).then(response => {
        this.setState({
          product: response.data,
          loading: false
        });
      });
    }
  };

  _loadAmbestRecord = () => {
    if (!this.state.ambest_record) {
      this.setState({
        loading: true
      });

      AlqApi.loadAmbestRecord(this.props.quote.carrier_id).then(response => {
        this.setState({
          ambest_record: response.data[0],
          loading: false
        });
      });
    }
  };

  _loadForms = () => {
    if (!this.state.forms) {
      this.setState({
        loading: true
      });

      const params = {
        carrier_id: this.props.quote.carrier_id,
        product_group_id: this.props.quote.product_group_id,
        state: this.props.quote.state
      };

      if (this.context.alq.is_beta) {
        params.beta = true;
      }

      AlqApi.loadQuoteForms(params).then(response => {
        AlqApi.loadFormTags().then(form_tags => {
          this.setState({
            form_tags,
            forms: response.data,
            loading: false
          });
        });
      });
    }
  };

  _handleDownloadStandardPacket = () => {
    this.setState({
      modal: 'standard_packet'
    });

    AlqApi.downloadStandardPacket({
      carrier_id: this.props.quote.carrier_id,
      product_group_id: this.props.quote.product_group_id,
      state: this.props.quote.state
    })
      .then(response => {
        this.setState({
          modal: null
        });

        window.open(response.data.url);
      })
      .catch(() => {
        this.setState({
          standard_packet_error: true
        });
      });
  };

  _handleQuoteAction = action => {
    const actions = {
      anico: 'anico',
      request_forms: 'request_forms',
      webhook_post: 'post_to_url',
      simple_url: 'open_url',
      default: 'open_url',
      typeform: 'open_url',
      formassembly: 'open_url',
      '123_contact_form': 'open_url',
      cognito_form: 'open_url',
      formsite: 'open_url',
      jotform: 'open_url',
      formstack: 'open_url',
      wufoo: 'open_url',
      google_form: 'open_url',
      applicant: 'applicint',
      nmb_term_eticket: 'nmb_term_eticket',
      nmb_whole_life_eticket: 'nmb_whole_life_eticket',
      paclife: 'paclife',
      protective: 'protective',
      firelight: 'firelight',
      psg_eapp: 'psg_eapp',
      download_quotes_pdf: 'share',
      sbli: 'sbli',
      send_quote_as_email: 'share',
      send_quote_as_text: 'share',
      standard_packet: 'standard_packet'
    };

    if (action.action_type === 'standard_packet') {
      this._handleDownloadStandardPacket();
    }

    this.setState({
      action,
      modal: actions[action.action_type]
    });
  };

  render() {
    const {
      action,
      active_details,
      ambest_record,
      anico_eticket_guid,
      firelight_eticket,
      firelight_eapp,
      form_tags,
      forms,
      loading,
      modal,
      nmb_eticket_guid,
      paclife_eticket_guid,
      product,
      protective_eticket_guid,
      psg_eapp,
      sbli_eticket_guid,
      show_details,
      standard_packet_error
    } = this.state;

    const { alq, client, agent, ran_quote_data } = this.context;
    const type = action?.action_type?.includes('download') ? 'download' : action?.action_type?.includes('email') ? 'email' : action?.action_type?.includes('text') ? 'text' : 'text';
    const quote_params = this.context.quoter_config.quote_params || [];
    const { customAction, onSelect, productFeatures, quote, selected } = this.props;

    const eticketCheck = this.context?.alq?.custom_actions.some(action => /e[-]?ticket/i.test(action.label) && action.carrier_ids?.includes(quote.carrier_id));

    const styles = this.styles();
    const base_lifedx_form_data = [
      { name: 'agency_name', value: alq.agency_name },
      { name: 'pi_first_name', value: client.first_name },
      { name: 'pi_last_name', value: client.last_name },
      { name: 'pi_date_of_birth', value: ran_quote_data.date_of_birth ? Moment(ran_quote_data.date_of_birth).format('YYYY-MM-DD') : null },
      { name: 'producer_first_name', value: agent.first_name },
      { name: 'producer_last_name', value: agent.last_name },
      { name: 'producer_email', value: agent.email },
      { name: 'producer_phone', value: agent.phone },
      // Protective fields
      { name: 'first_name', value: client.first_name },
      { name: 'last_name', value: client.last_name },
      { name: 'agent_first_name', value: agent.first_name },
      { name: 'agent_last_name', value: agent.last_name },
      { name: 'agent_phone', value: agent.phone },
      { name: 'agent_email', value: agent.email },
      ...quote_params
    ];

    const details = [
      {
        id: 'features',
        label: 'Features',
        loadContent: this._loadProduct,
        content:
          product && product.product_feature_ids && product.product_feature_ids.length ? (
            product.product_feature_ids.map(feature_id => {
              const feature = productFeatures.find(pf => pf.id === feature_id);

              return (
                <div key={feature.id} style={styles.feature}>
                  <i className='mdi mdi-check' style={styles.feature_icon} /> {feature.name}
                </div>
              );
            })
          ) : (
            <ZeroState icon='mdi-playlist-check' message="This product doesn't have any features" />
          )
      },
      {
        id: 'carrier',
        label: 'Carrier Overview',
        loadContent: this._loadAmbestRecord,
        content: <AmbestInfo details={ambest_record} />
      },
      {
        id: 'premiums',
        label: 'Premiums',
        content: <QuotePremiums quote={quote} />
      }
    ];
    const share_actions = {
      download_quotes_pdf: 'download',
      send_quote_as_email: 'email',
      send_quote_as_text: 'text'
    };

    if (quote.agent_message && quote.agent_message.length) {
      details.push({
        id: 'agent_message',
        label: 'Important Information',
        content: <div style={{ lineHeight: '1.8em' }}>{quote.agent_message}</div>
      });
    }

    if (alq.allow_forms) {
      details.splice(1, null, {
        id: 'forms',
        label: 'Forms',
        loadContent: this._loadForms,
        content: <QuoteForms forms={forms} formTags={form_tags} quote={quote} />
      });
    }

    return (
      <div className={this.props.className} style={styles.container}>
        {modal === 'quote_actions' ? <QuoteActionModal onAction={this._handleQuoteAction} onClose={this._toggleModal.bind(null, null)} quote={quote} /> : null}

        {modal === 'request_forms' ? <RequestFormsModal onBackClick={this._toggleModal.bind(null, 'quote_actions')} onClose={this._toggleModal.bind(null, null)} quote={quote} /> : null}

        {modal === 'post_to_url' ? <PostToUrlModal action={action} onClose={this._toggleModal.bind(null, null)} params={cleanQuote(quote, ran_quote_data, quote_params, agent, client)} /> : null}

        {modal === 'open_url' ? (
          <OpenUrlModal
            inline={action.open_in_modal}
            onClose={this._toggleModal.bind(null, null)}
            url={
              action.action_type === 'simple_url'
                ? action.url
                : getRedirectUrl({
                    action,
                    quote,
                    quote_params,
                    agent,
                    client,
                    selected_config: ran_quote_data
                  })
            }
          />
        ) : null}

        {modal === 'anico' ? (
          <LifedxEticketModal
            eticketGuid={anico_eticket_guid}
            formData={base_lifedx_form_data.concat([{ name: 'is_test', value: alq.use_anico_test_servers }])}
            key={anico_eticket_guid}
            onClose={this._toggleModal.bind(null, null)}
            onCreate={this._setEticket.bind(null, 'anico_eticket_guid')}
            provider='anico'
            quote={quote}
            title={action.label}
            token={alq.use_anico_test_servers ? ANICO_TEST_EMBED_TOKEN : ANICO_EMBED_TOKEN}
          />
        ) : null}

        {modal === 'applicint' ? <ApplicintModal onBackClick={this._toggleModal.bind(null, 'quote_actions')} onClose={this._toggleModal.bind(null, null)} quote={quote} /> : null}

        {modal === 'nmb_term_eticket' ? (
          <NmbTermEticketModal eticketGuid={nmb_eticket_guid} onClose={this._toggleModal.bind(null, null)} onCreate={this._setEticket.bind(null, 'nmb_eticket_guid')} quote={quote} />
        ) : null}

        {modal === 'nmb_whole_life_eticket' ? (
          <NmbWholeLifeEticketModal eticketGuid={nmb_eticket_guid} onClose={this._toggleModal.bind(null, null)} onCreate={this._setEticket.bind(null, 'nmb_eticket_guid')} quote={quote} />
        ) : null}

        {modal === 'paclife' ? (
          <LifedxEticketModal
            eticketGuid={paclife_eticket_guid}
            formData={base_lifedx_form_data.concat([{ name: 'is_test', value: alq.use_paclife_test_servers }])}
            key={paclife_eticket_guid}
            onClose={this._toggleModal.bind(null, null)}
            onCreate={this._setEticket.bind(null, 'paclife_eticket_guid')}
            provider='paclife'
            quote={quote}
            title={action.label}
            token={alq.use_paclife_test_servers ? PACLIFE_TEST_EMBED_TOKEN : PACLIFE_EMBED_TOKEN}
          />
        ) : null}

        {modal === 'protective' ? (
          <LifedxEticketModal
            eticketGuid={protective_eticket_guid}
            formData={base_lifedx_form_data.concat([
              { name: 'is_test', value: alq.use_protective_test_servers },
              { name: 'agency_code', value: alq.protective_agency_code },
              { name: 'agency_contract_number', value: alq.protective_contract_number }
            ])}
            key={protective_eticket_guid}
            onClose={this._toggleModal.bind(null, null)}
            onCreate={this._setEticket.bind(null, 'protective_eticket_guid')}
            provider='protective_life'
            quote={quote}
            title={action.label}
            token={alq.use_protective_test_servers ? PROTECTIVE_TEST_EMBED_TOKEN : PROTECTIVE_EMBED_TOKEN}
          />
        ) : null}

        {modal === 'firelight' && action.action_data.activity === 'e_ticket' ? (
          <FirelightEticketModal action={action} eticket={firelight_eticket} onClose={this._toggleModal.bind(null, null)} onSave={this._setFirelightEticket} quote={quote} />
        ) : null}

        {modal === 'firelight' && action.action_data.activity === 'e_app' ? (
          <FirelightEappModal action={action} eapp={firelight_eapp} onClose={this._toggleModal.bind(null, null)} onSave={this._setFirelightEapp} quote={quote} />
        ) : null}

        {modal === 'psg_eapp' ? <PsgEappModal eapp={psg_eapp} onClose={this._toggleModal.bind(null, null)} onCreate={this._setPsgEapp} quote={quote} /> : null}

        {modal === 'sbli' ? (
          <LifedxEticketModal
            eticketGuid={sbli_eticket_guid}
            formData={base_lifedx_form_data.concat([
              { name: 'is_test', value: alq.use_sbli_test_servers },
              { name: 'agency_code', value: alq.sbli_agency_code }
            ])}
            key={sbli_eticket_guid}
            onClose={this._toggleModal.bind(null, null)}
            onCreate={this._setEticket.bind(null, 'sbli_eticket_guid')}
            provider='sbli'
            quote={quote}
            title={action.label}
            token={alq.use_sbli_test_servers ? SBLI_TEST_EMBED_TOKEN : SBLI_EMBED_TOKEN}
          />
        ) : null}

        {modal === 'share' ? (
          <ShareQuotesModal
            action={share_actions[action.action_type]}
            onBackClick={this._toggleModal.bind(null, 'quote_actions')}
            onClose={this._toggleModal.bind(null, null)}
            selectedQuotes={[quote.guid]}
          />
        ) : null}

        {modal === 'standard_packet' ? (
          <Modal
            buttons={
              standard_packet_error
                ? [
                    {
                      color: '#e5e5e5',
                      fontColor: '#999',
                      children: 'Back',
                      onClick: this._toggleModal.bind(null, 'quote_actions'),
                      style: { margin: '0 10px 0 auto' }
                    },
                    {
                      children: 'Done',
                      onClick: this._toggleModal.bind(null, null)
                    }
                  ]
                : null
            }
            maxWidth={800}
            onClose={this._toggleModal.bind(null, null)}
            title='Standard Packet'
          >
            <div style={styles.modal_content}>{standard_packet_error ? <span>No Standard Packet forms were found for this carrier.</span> : <Loader />}</div>
          </Modal>
        ) : null}

        <div onClick={onSelect.bind(null, quote)} style={styles.checkbox_wrapper}>
          <div style={Object.assign({}, styles.checkbox, selected && styles.checkbox_marked)}>{selected ? <i className='mdi mdi-check-bold' style={styles.checkbox_icon} /> : null}</div>
        </div>
        <div style={styles.card}>
          {/* {customAction && CUSTOM_ACTIONS.INSTANT_ISSUE.includes(customAction) ? <i className='mdi mdi-flash' style={styles.action_icon} /> : null} */}
          {customAction || eticketCheck ? <i className='mdi mdi-text-box' style={styles.action_icon} title='E-ticket enabled' /> : null}
          <div onMouseEnter={this._toggleHover.bind(null, true)} onMouseLeave={this._toggleHover.bind(null, false)} style={styles.face}>
            <div style={Object.assign({}, styles.logo, { backgroundImage: `url(${quote.carrier_logo_url})` })} />
            <div style={styles.title}>
              <div style={styles.product_name}>{quote.product_name}</div>
              <div style={styles.health_category}>
                {quote.carrier_health_category} {quote.table_rate_letter ? `(Table ${quote.table_rate_letter})` : null}
              </div>
              <div style={styles.face_amount}>{Numeral(quote.face_amount).format('$0,0')}</div>
            </div>
            <div style={styles.premium}>
              {[
                { value: 'monthly', label: 'Monthly' },
                { value: 'quarterly', label: 'Quarterly' },
                { value: 'semi_annual', label: 'Semi-Annually' },
                { value: 'annual', label: 'Annually' }
              ].map(payment_mode => (
                <div key={payment_mode.value}>
                  {payment_mode.label}:<span style={styles.premium_amount}>{quote[`premium_${payment_mode.value}`] ? Numeral(quote[`premium_${payment_mode.value}`]).format('$0,00.00') : 'N/A'}</span>
                </div>
              ))}
            </div>
            <div style={styles.hover}>
              <Button onClick={this._toggleModal.bind(null, 'quote_actions')}>Get Started</Button>
            </div>
          </div>
          {show_details ? (
            <div style={styles.details}>
              <div style={styles.menu}>
                {details.map(item => (
                  <div
                    key={item.id}
                    onClick={this._toggleContent.bind(null, item.id, item.loadContent)}
                    style={Object.assign({}, styles.menu_item, item.id === active_details && styles.menu_item_active)}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
              <div style={styles.details_content}>{loading ? <Loader /> : details.find(item => item.id === active_details).content}</div>
            </div>
          ) : null}
          <div className='ixn-quote-card--show-details' onClick={this._toggleDetails} style={styles.expand_pane}>
            {show_details ? 'Hide Details' : 'Show Details'}
            <i className={show_details ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} style={{ fontSize: 18, marginBottom: 2 }} />
          </div>
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      container: {
        display: 'flex',
        marginBottom: 15
      },
      checkbox_wrapper: {
        padding: '65px 20px 20px 0px',
        cursor: 'pointer'
      },
      checkbox: {
        border: `2px solid ${Colors.GRAY.hex}`,
        borderRadius: 3,
        width: 22,
        height: 22,
        lineHeight: '20px',
        textAlign: 'center'
      },
      checkbox_marked: {
        background: Colors.GREEN.hex,
        border: `1px solid ${Colors.GREEN.hex}`,
        color: '#fff'
      },
      checkbox_icon: {
        fontSize: 16
      },
      card: {
        border: this.props.selected ? `1px solid ${Colors.GREEN.hex}` : '1px solid #e5e5e5',
        boxShadow: this.props.selected ? `0px 0px 0px 1px ${Colors.GREEN.hex}` : 'none',
        borderRadius: 4,
        background: '#fff',
        overflow: 'hidden',
        width: '100%'
      },
      face: {
        padding: '20px 15px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden'
      },
      hover: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: 220,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        transform: this.state.hovering ? 'translateX(0%)' : 'translateX(100%)',
        transition: 'all .2s'
      },
      logo: {
        height: 60,
        width: 150,
        flexShrink: 0,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginRight: 40,
        marginLeft: 20
      },
      title: {
        marginRight: 20
      },
      product_name: {
        fontSize: FontSizes.LARGE,
        fontWeight: FontWeights.MEDIUM,
        marginBottom: 4
      },
      health_category: {
        fontSize: 13,
        color: '#777',
        margin: '6px 0 8px',
        fontStyle: 'italic'
      },
      face_amount: {
        fontSize: FontSizes.LARGE,
        fontWeight: FontWeights.MEDIUM
      },
      premium: {
        textAlign: 'right',
        marginLeft: 'auto',
        flexShrink: 0,
        color: '#777',
        fontSize: FontSizes.MEDIUM,
        lineHeight: '2em'
      },
      premium_amount: {
        fontWeight: FontWeights.BOLD,
        color: Colors.GRAY.hex,
        marginLeft: 6,
        fontSize: FontSizes.LARGE
      },
      details: {
        position: 'relative'
      },
      expand_pane: {
        background: '#f5f5f5',
        borderTop: '1px solid #e5e5e5',
        borderRadius: '0 0 4px 4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 26,
        fontSize: 10,
        textTransform: 'uppercase',
        color: '#999',
        fontWeight: FontWeights.MEDIUM,
        letterSpacing: 1,
        cursor: 'pointer'
      },
      action_icon: {
        background: this.context.color,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 3,
        color: 'fff',
        fontSize: 20,
        padding: 5,
        position: 'absolute'
      },
      menu: {
        display: 'flex',
        alignItems: 'bottom',
        borderBottom: '1px solid #e5e5e5'
      },
      menu_item: {
        fontWeight: FontWeights.MEDIUM,
        padding: '10px 20px',
        cursor: 'pointer'
      },
      menu_item_active: {
        color: this.context.color,
        boxShadow: `inset 0px -2px 0px 0px ${this.context.color}`
      },
      details_content: {
        padding: 20,
        minHeight: 200,
        overflow: 'auto'
      },
      feature: {
        lineHeight: '30px',
        display: 'flex',
        alignItems: 'center'
      },
      feature_icon: {
        fontSize: 18,
        color: Colors.GREEN.hex,
        marginRight: 10
      },
      modal_content: {
        minWidth: 400,
        padding: 40,
        fontSize: FontSizes.MEDIUM,
        lineHeight: '1.5em',
        position: 'relative',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    };
  };
}

QuoteCard.contextType = ProductContext;

export default QuoteCard;
