import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import CarrierForms from 'shared/CarrierForms';
import Input from 'shared/Input';
import Loader from 'shared/Loader';
import Select from 'shared/Select';

import { Colors, FontWeights } from 'constants/Clementine';

import AlqApi from 'utils/AlqApi';

class QuoteForms extends React.Component {
  static propTypes = {
    forms: PropTypes.array,
    formTags: PropTypes.array,
    quote: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: '',
      form_tag_id: '',

      selected_forms: []
    };
  }

  _handleSelectAll = filtered_forms => {
    const { selected_forms } = this.state;

    this.setState({
      selected_forms: selected_forms.length ? [] : filtered_forms.map(f => f.guid)
    });
  };

  _handleFieldChange = (name, e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  _handleFormClick = selected_forms => {
    this.setState({
      selected_forms
    });
  };

  _handleDownloadClick = () => {
    this.setState({
      loading: true
    });

    AlqApi.generateFormsPdf({
      carrier_id: this.props.quote.carrier_id,
      product_group_id: this.props.quote.product_group_id,
      state: this.props.quote.state,
      form_guids: this.state.selected_forms,
      pdf_action: 'download'
    }).then(response => {
      this.setState({
        loading: false
      });

      window.open(response.data.pdf_url);
    });
  };

  render() {
    const styles = this.styles();
    const { forms, formTags } = this.props;
    const { selected_forms, loading, search, form_tag_id } = this.state;
    const filtered_forms = forms.filter(f => {
      const s = search.toLowerCase();
      const form_name = f.form_name ? f.form_name.toLowerCase() : '';
      const form_number = f.form_number ? f.form_number.toLowerCase() : '';
      const form_tag_ids = f.form_tag_ids || [];
      const matches_search = s.length ? form_name.includes(s) || form_number.includes(s) : true;
      const matches_form_tag = form_tag_id.length ? form_tag_ids.includes(form_tag_id) : true;

      return matches_search && matches_form_tag;
    });

    return (
      <div style={styles.container}>
        {loading ? (
          <div style={styles.loader}>
            <Loader />
          </div>
        ) : null}

        <div style={styles.header}>
          <Button disabled={!filtered_forms.length} onClick={this._handleSelectAll.bind(null, filtered_forms)}>
            <i className='mdi mdi-checkbox-marked-outline' style={{ fontSize: 18, verticalAlign: 'top', marginLeft: -3 }} />
            {selected_forms.length ? ' Unselect' : ' Select'} All
          </Button>

          <div style={{ display: 'flex' }}>
            <Input onChange={this._handleFieldChange.bind(null, 'search')} placeholder='Search by name or number...' style={{ marginRight: 10, width: 200, flexShrink: 0 }} value={search} />
            <Select
              onChange={this._handleFieldChange.bind(null, 'form_tag_id')}
              options={[{ value: '', label: 'All Categories' }].concat(this.props.formTags.map(ft => ({ value: ft.id, label: ft.name })))}
              selected={form_tag_id}
            />
          </div>
        </div>

        {selected_forms.length ? (
          <div style={styles.selected_bar}>
            <div style={styles.selected_bar_count}>{selected_forms.length} Forms Selected</div>
            <Button onClick={this._handleDownloadClick} small={true}>
              <i className='mdi mdi-file-pdf' /> Download
            </Button>
          </div>
        ) : null}

        <div style={styles.content}>
          <CarrierForms forms={filtered_forms} formTags={formTags} layout='list' onFormClick={this._handleFormClick} selectedForms={selected_forms} />
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      container: {
        position: 'relative'
      },
      loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
        background: 'rgba(255,255,255,0.95)'
      },
      header: {
        paddingBottom: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      content: {
        maxHeight: 400,
        overflow: 'auto',
        border: '1px solid #e5e5e5'
      },
      selected_bar: {
        background: Colors.GRAY.hex,
        color: '#fff',
        padding: '10px 15px 10px 18px',
        fontWeight: FontWeights.MEDIUM,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      btn_icon: {
        fontSize: 18,
        verticalAlign: 'top'
      }
    };
  };
}

export default QuoteForms;
